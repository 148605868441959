import React from "react";
import KidWelcome from "../../../../assets/sidebar/kid-welcome.jpg";
import "./WhySunnyside.css";

const WhySunnyside = () => {
  return (
    <div className="why-sunnyside subpage">
      <header className="why-sunnyside-header subpage-header">
        <h1>The Sunnyside Story</h1>
      </header>
      <section className="subpage-content why-sunnyside-content">
        <article className="why-specialize">
          <header>
            <br />
            <h2>Welcome to your Dental Home</h2>
          </header>
          <p>
            <br />
            Dr. Sophie founded Sunnyside Pediatric Dentistry with the goal of
            providing a small, personal pediatric dental home experience for her
            patients and their families.
          </p>
          <br />
          <p>
            With a special focus on personalized treatment plans and
            behaviorally guided approaches to care, Dr. Sophie wants to help her
            patients develop the habits that promote lifelong oral health.
            Understanding that no two children are alike, she values taking the
            time to get to know each individual child so that she can best
            connect with them to promote the confidence, cooperation and trust
            that is important for positive experiences at the dentist.
          </p>
        </article>
        <img
          src={KidWelcome}
          alt="Kid giving handshake"
          className="kid-welcome side-img"
        />
        <article className="what-training">
          <p>
            Dr. Sophie grew up in Berkeley, and always knew that she wanted to
            open her practice in the Bay Area. After a long search to find the
            perfect community, she knew West San Rafael, CA was the right fit
            for Sunnyside.
          </p>
        </article>
        <article className="what-inspired">
          <p>
            Upon completion of a top to bottom renovation, including ADA updates
            and the addition of state of the art technology and equipment, we
            opened our doors in the Fall of 2016. Since then we have been
            steadily growing our patient base, and continue to welcome new
            families looking to establish their pediatric dental home with us.
          </p>
        </article>
      </section>
    </div>
  );
};

export default WhySunnyside;
