import React from "react";

const Fruit = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      x="0"
      y="0"
      fill="#1A1A1A"
      viewBox="0 0 100 100"
    >
      <path d="M48.07 16.28a3.84 3.84 0 10-3.84-3.84 3.85 3.85 0 003.84 3.84zm0-5.68a1.84 1.84 0 11-1.84 1.84 1.84 1.84 0 011.84-1.84zM60.07 23.28a3.84 3.84 0 10-3.84-3.84 3.85 3.85 0 003.84 3.84zm0-5.68a1.84 1.84 0 11-1.84 1.84 1.84 1.84 0 011.84-1.84zM12.07 24.28a3.84 3.84 0 10-3.84-3.84 3.85 3.85 0 003.84 3.84zm0-5.68a1.84 1.84 0 11-1.84 1.84 1.84 1.84 0 011.84-1.84zM44.23 53.44a3.84 3.84 0 103.84-3.84 3.85 3.85 0 00-3.84 3.84zm5.68 0a1.84 1.84 0 11-1.84-1.84 1.84 1.84 0 011.85 1.84zM66.85 33.27a3.82 3.82 0 002.27.75 3.91 3.91 0 00.59 0 3.82 3.82 0 002.52-1.53 3.88 3.88 0 00.29-.47 9 9 0 001.15.08c3.73 0 7.73-2.21 10.45-5.92a14.16 14.16 0 002.59-11.5 3.54 3.54 0 00.5-.53 5.07 5.07 0 00-1.07-7 4.87 4.87 0 00-6.9.89c-3.91.17-8.09 2.58-10.77 6.23-3 4.14-3.85 9.58-2.08 13.3a3.74 3.74 0 00.51 5.73zM70 15.42c2.42-3.29 6.19-5.42 9.6-5.42.31 0 .63-.07 1.21-.85a2.9 2.9 0 014.1-.46 3.08 3.08 0 01.65 4.23 2.34 2.34 0 01-.24.23 2.31 2.31 0 00-.69.88 1.25 1.25 0 00-.06.22 1 1 0 000 .44 12.15 12.15 0 01-2.07 10.26c-2.71 3.69-7 5.71-10.38 4.93a1 1 0 00-1.16.67 1 1 0 00-.06.14A1.85 1.85 0 0169.41 32a1.85 1.85 0 01-1.77-2.92 1.84 1.84 0 01.49-.46.94.94 0 00.1-.1 1 1 0 00.18-.18 1 1 0 00.06-.13.55.55 0 00.1-.38 1 1 0 000-.22 1 1 0 00-.07-.19 1 1 0 00-.06-.17c-1.84-3-1.15-8.08 1.56-11.83z"></path>
      <path d="M69.61 24.64h.16a1 1 0 001-.84 13.81 13.81 0 017-9.64 1 1 0 00-.92-1.78 15.81 15.81 0 00-8 11.09 1 1 0 00.76 1.17zM25.55 69.16a1 1 0 00-1.74 1q.47.83 1 1.62a1 1 0 001.67-1.11q-.48-.74-.93-1.51zM37.24 80c-.65-.33-1.3-.69-1.93-1.06s-1.49-.94-2.19-1.44a27.93 27.93 0 01-3.39-2.89l-.38-.37a7.06 7.06 0 01-.62-.67 1 1 0 00-1.53 1.29 8.68 8.68 0 00.76.82l.35.35a29.93 29.93 0 003.64 3.1c.75.54 1.53 1.06 2.34 1.54s1.35.78 2 1.13a1 1 0 10.9-1.78zM75.62 42.53a27.48 27.48 0 01-5.74 4.81A1 1 0 0070.95 49a29.48 29.48 0 006.16-5.16 1 1 0 10-1.48-1.34zM66.49 49.27a14.34 14.34 0 00-6.87 8.17 1 1 0 101.91.6 12.35 12.35 0 015.93-7 1 1 0 00-1-1.75z"></path>
      <path d="M96.86 32.81c-.16-.08-4-1.95-7.5-.66a7.22 7.22 0 00-1.1.51c.37-.86.72-1.73 1-2.62a1 1 0 00-1.9-.64 31.66 31.66 0 01-3.4 7 8.14 8.14 0 00-10.27 1.7l-2.06 2.28a27.89 27.89 0 01-7.17 5.68l-2.11 1.17a14.55 14.55 0 00-7 8.29 14.39 14.39 0 00-.59 3.48H38a14.48 14.48 0 001.7-14.92c-2.92-6.38-7.54-9.69-12.36-8.87a9.17 9.17 0 00-4.11 2.22l-.72.56a33.76 33.76 0 01-2.61-8 1 1 0 10-2 .37c.2 1 .46 2.08.75 3.1a7.72 7.72 0 00-1.23-.81c-3.51-1.85-7.78-.45-8-.39a1 1 0 00-.64 1.22c.05.18 1.3 4.5 4.81 6.35a7.77 7.77 0 001.65.62l-.24.16c-4.83 3.55-4 10.39-1.67 15.53a14.52 14.52 0 002 3.15A1 1 0 0015 60c0 18.25 14.39 31 35 31s35-12.75 35-31a1 1 0 00-1-1h-.71v-.13a27.86 27.86 0 012.63-8.76l1.34-2.73a8 8 0 00-.11-7.74 10.54 10.54 0 002.16.24 7.84 7.84 0 002.69-.45c3.53-1.28 5.26-5.16 5.33-5.33a1 1 0 00-.47-1.29zM83 61a31.32 31.32 0 01-.38 4H17.4a31.31 31.31 0 01-.4-4h66zM11.13 33.89a8.49 8.49 0 015.39.51A8.49 8.49 0 0120 38.56a8.45 8.45 0 01-5.41-.56 8.49 8.49 0 01-3.46-4.11zm5.11 8.32a7.51 7.51 0 013.14-1 10.82 10.82 0 002.76-.69A11.68 11.68 0 0024.48 39a7.44 7.44 0 013.2-1.81c5.74-1 9.26 5.67 10.2 7.73A12.47 12.47 0 0135.29 59H17.61a1 1 0 00-.19-.31 12.5 12.5 0 01-2.27-3.38c-2.04-4.46-2.83-10.24 1.09-13.1zM50 89c-16.95 0-29.12-8.59-32.22-22h64.44C79.12 80.41 66.95 89 50 89zm35.48-42.5l-1.34 2.72a29.89 29.89 0 00-2.82 9.39v.38H56.77a12.41 12.41 0 01.55-2.84 12.56 12.56 0 016-7.14l2.11-1.17a29.88 29.88 0 007.68-6.09l2.06-2.28a6.27 6.27 0 013.42-2 6.27 6.27 0 016.85 9zm5.84-9a7.86 7.86 0 01-5-.19A7.81 7.81 0 0190 34a7.84 7.84 0 015 .19 7.85 7.85 0 01-3.68 3.36z"></path>
      <path d="M17.47 49a1.15 1.15 0 001-1.2 12.25 12.25 0 01.14-2.36 1 1 0 00-.81-1.14 1 1 0 00-1.16.86 13.38 13.38 0 00-.17 2.7 1.17 1.17 0 001 1.15zM20.47 58.39a1 1 0 101.53-1.3 12.51 12.51 0 01-1.88-2.95 19.82 19.82 0 01-1.24-3.55 1 1 0 00-1.94.47A21.82 21.82 0 0018.29 55a14.52 14.52 0 002.18 3.39zM49.42 46.52a10.92 10.92 0 002.83.38 9.91 9.91 0 005.41-1.58 10.16 10.16 0 004.44-7 12.19 12.19 0 00-1.86-8.58 12.19 12.19 0 00-7.05-5.24 10 10 0 00-12.68 8.2 12.48 12.48 0 008.91 13.82zm1-20.41a8.9 8.9 0 012.31.31 10.19 10.19 0 015.89 4.39A10.19 10.19 0 0160.18 38a8.53 8.53 0 01-1.56 3.73 15.39 15.39 0 00-1.93-9.27 13.47 13.47 0 00-8.59-6 8.08 8.08 0 012.32-.34zM42.48 33A8.32 8.32 0 0145 28.22h.11A11.1 11.1 0 0155 33.53c1.84 2.86 2 7.33 1.23 10.3a8.21 8.21 0 01-6.31.76A10.48 10.48 0 0142.48 33z"></path>
      <circle
        cx="27.87"
        cy="23.63"
        r="0.88"
        transform="rotate(-30.66 27.88 23.625)"
      ></circle>
      <circle
        cx="32.49"
        cy="25.54"
        r="0.88"
        transform="rotate(-30.66 32.494 25.537)"
      ></circle>
      <circle
        cx="33.57"
        cy="15.6"
        r="0.88"
        transform="rotate(-30.66 33.572 15.596)"
      ></circle>
      <circle
        cx="34.91"
        cy="21.78"
        r="0.88"
        transform="rotate(-30.66 34.914 21.788)"
      ></circle>
      <circle
        cx="30.29"
        cy="19.87"
        r="0.88"
        transform="rotate(-30.66 30.3 19.876)"
      ></circle>
      <circle
        cx="29.11"
        cy="15.92"
        r="0.88"
        transform="rotate(-30.66 29.11 15.922)"
      ></circle>
      <circle
        cx="24.46"
        cy="19.84"
        r="0.88"
        transform="rotate(-30.66 24.465 19.845)"
      ></circle>
      <path d="M20.38 13.19l3.07.78c-3.55 3-4.44 6.67-2.32 10.25 1.81 3.05 8.1 6.07 11.45 6.61a5.24 5.24 0 005.78-3.43c1.13-3.21 1.5-10.17-.31-13.22-2.05-3.46-5.56-4.51-9.67-3L29.19 8a1 1 0 10-1.94-.5l-.95 3.71L25 9a1 1 0 10-1.72 1l1.32 2.22-3.71-.95a1 1 0 10-.5 1.94zm16 2c1.36 2.3 1.23 8.44.14 11.53a3.24 3.24 0 01-3.58 2.12c-3.23-.52-8.69-3.36-10.05-5.66a5 5 0 01-.4-4.91 10.11 10.11 0 014.17-4.11 10.83 10.83 0 015.2-1.7 5 5 0 014.47 2.75z"></path>
    </svg>
  );
};

export default Fruit;
