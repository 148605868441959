import React from "react";

const Anesthesia = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      height="100"
      width="100"
      enableBackground="new 0 0 34 60"
      viewBox="0 0 34 60"
      className="anesthesia-icon"
    >
      <path d="M30 60H18c-2.206 0-4-1.794-4-4V30c0-2.206 1.794-4 4-4h12c2.206 0 4 1.794 4 4v26c0 2.206-1.794 4-4 4zM18 28c-1.103 0-2 .897-2 2v26c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V30c0-1.103-.897-2-2-2H18z"></path>
      <path d="M30 28H18a1 1 0 01-1-1v-2.397A2.605 2.605 0 0119.603 22h8.795A2.605 2.605 0 0131 24.603V27a1 1 0 01-1 1zm-11-2h10v-1.397a.604.604 0 00-.603-.603h-8.795a.603.603 0 00-.602.603V26z"></path>
      <path d="M26 24h-4a1 1 0 01-1-1v-6a1 1 0 011-1h4a1 1 0 011 1v6a1 1 0 01-1 1zm-3-2h2v-4h-2v4z"></path>
      <path d="M26 18h-8a1 1 0 01-1-1V9a1 1 0 011-1h8c2.757 0 5 2.243 5 5s-2.243 5-5 5zm-7-2h7c1.654 0 3-1.346 3-3s-1.346-3-3-3h-7v6z"></path>
      <path d="M18 16h-4a1 1 0 01-1-1v-4a1 1 0 011-1h4a1 1 0 011 1v4a1 1 0 01-1 1zm-3-2h2v-2h-2v2zM25 50H15a1 1 0 01-1-1V37a1 1 0 011-1h10a1 1 0 011 1v12a1 1 0 01-1 1zm-9-2h8V38h-8v10zM33 50h-4a1 1 0 01-1-1V37a1 1 0 011-1h4a1 1 0 011 1v12a1 1 0 01-1 1zm-3-2h2V38h-2v10z"></path>
      <path d="M21 42h-6a1 1 0 010-2h6a1 1 0 010 2zM19 46h-4a1 1 0 010-2h4a1 1 0 010 2zM3 26c-1.654 0-3-1.346-3-3V3c0-1.654 1.346-3 3-3s3 1.346 3 3v20c0 1.654-1.346 3-3 3zM3 2c-.552 0-1 .449-1 1v20a1.001 1.001 0 002 0V3c0-.551-.448-1-1-1z"></path>
      <path d="M5 23a1 1 0 01-1-1V4a1 1 0 011-1c5.514 0 10 4.486 10 10S10.514 23 5 23zM6 5.062v15.876c3.941-.494 7-3.866 7-7.938S9.941 5.556 6 5.062z"></path>
    </svg>
  );
};

export default Anesthesia;
