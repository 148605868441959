import React from "react";
import Meghan from "../../../../assets/employees/meghan.jpg";
import Olivia from "../../../../assets/employees/olivia.jpg";
import Placeholder from "../../../../assets/employees/placeholder.png";
import "./MeetTheTeam.css";

const MeetTheTeam = () => {
  return (
    <div className="meet-the-team subpage">
      <header className="meet-the-team-header subpage-header">
        <h1>Meet the Team</h1>
      </header>
      <section className="subpage-content employees">
        <article>
          <header>
            <h2>Meghan</h2>
            <h3>Lead Front Desk</h3>
          </header>
          <img src={Meghan} alt="Meaghan front desk" />
          <p>
            Hi I'm Meaghan! I grew up in San Francisco and moved to Marin a few
            years ago. My background is in the mortgage insurance industry but
            after the birth of my two sons, I was looking for a change of
            careers. I love working at Sunnyside and seeing how much our
            patients enjoy their dental experience with Dr. Sophie and her
            assistants. I like to spend free time with my family and enjoy life
            in Marin County. We often visit the Discovery Museum, go swimming
            and spend lots of time outside at one of the many beautiful parks!
          </p>
        </article>
        <article>
          <header className="olivia-header">
            <h2>Olivia</h2>
            <h3>Lead Dental Assistant</h3>
          </header>
          <img src={Olivia} alt="Olivia" />
          <p>
            Hello, my name is Olivia. I am from Sonoma/Lake Counties and started
            my career in Pediatric Dentistry 3 years ago. I love working with
            children and teaching and pediatric dentistry allows me to do both!
            It's very important to me to make sure all of our patients and their
            families are comfortable during their visits and leave feeling more
            knowledgable about their oral health and hygiene. Outside of
            Sunnyside, I enjoy the company of my family and friends. I am a big
            shopper, DIY gal and “natural living mama” to my amazing and
            beautiful daughters.
          </p>
        </article>
        <article>
          <header>
            <h2>Jamie</h2>
            <h3>Dental Assistant</h3>
          </header>
          <img src={Placeholder} alt="placeholder" />
          <p>Coming soon</p>
        </article>
      </section>
    </div>
  );
};

export default MeetTheTeam;
