import React from "react";

const Sedation = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      x="0"
      y="0"
      fill="#1A1A1A"
      viewBox="0 0 56 56"
      className="sedation-icon"
    >
      <path d="M7.474 38.469C9.562 45.325 15.838 50.048 23 50.048c7.178 0 13.441-4.714 15.531-11.571h.119c2.63 0 4.77-2.14 4.77-4.769 0-2.675-2.205-4.83-4.889-4.778-1.844-6.041-7.01-10.492-13.217-11.39a10.632 10.632 0 00-1.564-3.532 1.947 1.947 0 00-1.388-.836 1.923 1.923 0 00-1.508.493l-3.053 2.803a2.852 2.852 0 00-1.517.07 2.855 2.855 0 00-1.26.847l-4.121-.432a1.892 1.892 0 00-1.512.508c-.411.385-.633.93-.61 1.494.068 1.768.544 3.38 1.374 4.83-1.189 1.555-2.113 3.306-2.678 5.152A4.782 4.782 0 003 33.708a4.776 4.776 0 004.474 4.76zm14.668-23.27c.92 1.372 1.415 2.85 1.47 4.395l-3.554-.373a2.872 2.872 0 00-.133-.85 2.875 2.875 0 00-.404-.768l2.62-2.405zm-5.23 3.238a.883.883 0 11.555 1.676.883.883 0 01-.556-1.676zm-2.594.884c.005.288.04.577.133.858.092.279.235.53.4.763l-2.633 2.417c-.878-1.28-1.36-2.763-1.435-4.408l3.535.37zm-6.215 11.63A1.01 1.01 0 009.2 30.2c.441-1.757 1.257-3.437 2.343-4.93a1.927 1.927 0 00.665.125 1.93 1.93 0 001.298-.504l3.056-2.806c.205.046.413.073.623.073a2.855 2.855 0 002.173-1.001l4.139.435a1.916 1.916 0 001.502-.507 1.93 1.93 0 00.614-1.47v-.007c5.434 1.021 9.863 5.176 11.216 10.668a1 1 0 001.223.729c.201-.053.397-.078.598-.078a2.778 2.778 0 012.77 2.78 2.773 2.773 0 01-2.77 2.77c-.23 0-.41-.022-.566-.069a1.003 1.003 0 00-1.255.719c-1.588 6.43-7.274 10.92-13.829 10.92-6.509 0-12.184-4.456-13.8-10.836-.118-.466-.596-.769-1.08-.75-.12.009-.228.016-.35.016A2.773 2.773 0 015 33.708c0-1.65 1.458-2.96 3.103-2.758z"></path>
      <path d="M26.665 32.442a1 1 0 001-1 1.152 1.152 0 012.302 0 1 1 0 102 0c0-1.737-1.414-3.15-3.151-3.15s-3.151 1.413-3.151 3.15a1 1 0 001 1zM15.45 32.442a1 1 0 001-1 1.152 1.152 0 012.301 0 1 1 0 102 0c0-1.737-1.414-3.15-3.15-3.15s-3.152 1.413-3.152 3.15a1 1 0 001 1zM35.973 20.032a1 1 0 00.91.585h5.326a1 1 0 100-2h-3.134l3.89-4.478a1 1 0 00-.756-1.656h-5.327a1 1 0 100 2h3.135l-3.89 4.479a1 1 0 00-.154 1.07zM52 10.22h-1.514l2.269-2.613A1 1 0 0052 5.952h-3.707a1 1 0 100 2h1.514l-2.268 2.612a1 1 0 00.754 1.656H52a1 1 0 100-2zM19.887 39.838a1 1 0 00-1 1c0 1.615 1.947 3.493 4.458 3.493 2.348 0 4.652-1.73 4.652-3.493a1 1 0 10-2 0c0 .361-1.1 1.493-2.652 1.493-1.478 0-2.458-1.062-2.458-1.493a1 1 0 00-1-1z"></path>
    </svg>
  );
};

export default Sedation;
