import React from "react";

const Instagram = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      fill="#1A1A1A"
      data-name="Layer 1"
      viewBox="0 0 24 24"
      className="instagram-icon"
    >
      <path d="M20 0H4a4 4 0 00-4 4v16a4 4 0 004 4h16a4 4 0 004-4V4a4 4 0 00-4-4zm2 20a2 2 0 01-2 2H4a2 2 0 01-2-2V4a2 2 0 012-2h16a2 2 0 012 2z"></path>
      <path d="M12 6a6 6 0 106 6 6 6 0 00-6-6zm0 10a4 4 0 114-4 4 4 0 01-4 4z"></path>
      <circle cx="18.5" cy="5.5" r="1.5"></circle>
    </svg>
  );
};

export default Instagram;
