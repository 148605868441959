import React from "react";

const TextIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 56 56"
      viewBox="0 0 56 56"
      className="text-icon"
    >
      <path d="M4.715 50.214a1.003 1.003 0 001.033.124l10.67-4.855c3.556 1.573 7.55 2.403 11.582 2.403 13.785 0 25-9.49 25-21.157S41.785 5.572 28 5.572 3 15.063 3 26.73c0 3.853 1.232 7.61 3.57 10.898L4.35 49.24a1 1 0 00.364.974zM5 26.729C5 16.166 15.318 7.572 28 7.572s23 8.594 23 19.157-10.318 19.157-23 19.157c-3.908 0-7.771-.834-11.171-2.413a.995.995 0 00-.927.048l-9.224 4.197 1.924-10.072a.998.998 0 00-.165-.851C6.188 33.77 5 30.29 5 26.729z"></path>
      <path d="M16.368 30.48c2.068 0 3.75-1.683 3.75-3.75s-1.682-3.75-3.75-3.75-3.75 1.682-3.75 3.75 1.682 3.75 3.75 3.75zm0-5.5c.965 0 1.75.785 1.75 1.75s-.785 1.75-1.75 1.75-1.75-.786-1.75-1.75.785-1.75 1.75-1.75zM28 30.48c2.068 0 3.75-1.683 3.75-3.75s-1.682-3.75-3.75-3.75-3.75 1.682-3.75 3.75 1.682 3.75 3.75 3.75zm0-5.5c.965 0 1.75.785 1.75 1.75s-.785 1.75-1.75 1.75-1.75-.786-1.75-1.75.785-1.75 1.75-1.75zM39.632 30.48c2.068 0 3.75-1.683 3.75-3.75s-1.682-3.75-3.75-3.75-3.75 1.682-3.75 3.75 1.682 3.75 3.75 3.75zm0-5.5c.965 0 1.75.785 1.75 1.75s-.785 1.75-1.75 1.75-1.75-.786-1.75-1.75.785-1.75 1.75-1.75z"></path>
    </svg>
  );
};

export default TextIcon;
