import React, { useState } from "react";
import axios from "axios";
import "./Contact.css";

const Contact = () => {
  const contactAPIPath = "api/contact_form.php";

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [preference, setPreference] = useState("");
  const [message, setMessage] = useState("");
  const [mailSent, setMailSent] = useState(false);
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setError(null);
    if (firstName === "" || lastName === "" || email === "" || message === "") {
      setError("A first and last name, email and message are required.");
      return;
    }
    axios({
      method: "post",
      url: contactAPIPath,
      headers: { "content-type": "application/json" },
      data: {
        firstName: firstName,
        lastName: lastName,
        email: email,
        phone: phone,
        preference: preference,
        message: message,
      },
    })
      .then((result) => {
        if (result.data.error) {
          setErrorMessage(result.data.error_message);
        }
        setMailSent(result.data.sent);
      })
      .catch((error) => setError(error));
  };

  return (
    <div className="contact subpage">
      <header className="contact-header subpage-header">
        <h1>Contact Us</h1>
      </header>
      <section className="welcome">
        <article>
          <p>
            Call us today to schedule an appointment or fill out our contact
            form and someone will respond by the following business day!
          </p>
        </article>
      </section>
      <section className="subpage-content contact-content">
        <article id="contact-list" className="contact-list">
          <header>
            <h2>Contact</h2>
          </header>
          <address>
            <a
              href="https://goo.gl/maps/pjqtAoiNqBrC5w4N9"
              target="_blank"
              rel="noreferrer noopener"
            >
              1721 Fifth Ave. Suite A <br /> San Rafael, CA 94901
            </a>
          </address>
          <ul>
            <li>
              Phone: <a href="tel:4157853445">(415) 785-3445</a>
            </li>
            <li>
              Text: <a href="sms:4157853445">(415) 785-3445</a>
            </li>
            <li>Fax: (415) 785-3445</li>
            <li>
              <a href="mailto:hello@sunnysidesmiles.com">
                hello@sunnysidesmiles.com
              </a>
            </li>
          </ul>
        </article>
        <article className="hours-parking">
          <header>
            <h2 className="contact-hours-title">Office Hours & Parking</h2>
          </header>
          <p className="contact-hours">
            Monday - Friday <br /> 9:00 AM - 5:00 PM
          </p>
          <br />
          <p className="parking">
            There is plenty of street parking on Fifth Ave. Please allow a few
            extra minutes to park, thank you!
          </p>
        </article>
        {!mailSent ? (
          <form action="#" className="contact-form" id="contact-form">
            <h2>Contact Us</h2>
            <p>Name:</p>
            <div className="name-form">
              <input
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                name="first-name"
                id="first-name"
                placeholder="First"
                required
              />
              <input
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                name="last-name"
                id="last-name"
                placeholder="Last"
                required
              />
            </div>
            <p>Email:</p>
            <input
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              name="email"
              id="email"
              required
            />
            <p>Phone:</p>
            <input
              type="tel"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              name="phone"
              id="phone"
            />
            <p>How would you like to be contacted?</p>
            <input
              type="radio"
              id="phone-radio"
              name="contact-preference"
              onChange={() => setPreference("Phone")}
            />
            <label htmlFor="phone-radio">Phone</label>
            <br />
            <input
              type="radio"
              id="radio-email"
              name="contact-preference"
              value="email"
              onChange={() => setPreference("Email")}
            />
            <label htmlFor="email-radio">Email</label>
            <br />
            <input
              type="radio"
              id="no-preference"
              name="contact-preference"
              value="no-preference"
              onChange={() => setPreference("No Preference")}
            />
            <label htmlFor="no-preference">No Preference</label>
            <br />
            <p>Message:</p>
            <textarea
              id="contact-message"
              name="contact-message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              form="contact-form"
              rows="5"
              className="textarea"
              placeholder="How may we help you?"
              required
            />
            <br />
            {!error ? null : <p className="error-message">{error}</p>}
            {error || !errorMessage ? null : (
              <p className="error-message">{errorMessage}</p>
            )}
            <input
              type="submit"
              value="Submit"
              id="contact-submit-btn"
              onClick={(e) => handleFormSubmit(e)}
            />
          </form>
        ) : (
          <span className="submitted-message">Thank you for your message.</span>
        )}
      </section>
    </div>
  );
};

export default Contact;
