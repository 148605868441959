import React from "react";
import { HashLink } from "react-router-hash-link";
import Logo from "../../../../assets/logos/main-logo-removed.png";
import GirlFrameMobile from "../../../../assets/girl-frame-mobile-hero.jpg";
import "./HomeWelcome.css";

const HomeWelcome = () => {
  const scrollWidthOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -120;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };
  return (
    <div className="home-welcome">
      <div className="mobile-intro">
        <img src={Logo} alt="Sunnyside Logo" className="mobile-logo" />
        <address>
          1721 Fifth Ave. Suite A<br />
          San Rafael, CA 94901
        </address>
      </div>
      <img
        src={GirlFrameMobile}
        alt="Girl Smiling"
        className="girl-smile-mobile-hero"
      />
      <article className="home-welcome-content">
        <img src={Logo} alt="Sunnyside Logo" className="welcome-logo" />
        <HashLink
          to="/contact#contact-form"
          scroll={scrollWidthOffset}
          className="home-welcome-btn"
        >
          <button>Make an Appointment</button>
        </HashLink>
      </article>
    </div>
  );
};

export default HomeWelcome;
