import React, { Suspense } from "react";
import HomeWelcome from "./HomeWelcome/HomeWelcome";
import SunnysideDifference from "./SunnysideDifference/SunnysideDifference";
import DrSophieHome from "./DrSophieHome/DrSophieHome";
import OurServicesHome from "./OurServicesHome/OurServicesHome";
import ReviewsHome from "./ReviewsHome/ReviewsHome";
import CovidHome from "../HOME/CovidHome/CovidHome";
import "./Home.css";
const HomeContact = React.lazy(() => import("./HomeContact/HomeContact"));

const Home = () => {
  return (
    <div className="home">
      <HomeWelcome />
      <SunnysideDifference />
      <DrSophieHome />
      <OurServicesHome />
      <ReviewsHome />
      <Suspense fallback={<div>loading</div>}>
        <HomeContact />
      </Suspense>
      <CovidHome />
    </div>
  );
};

export default Home;
