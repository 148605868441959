import React from "react";

const DentistChair = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      x="0"
      y="0"
      fill="#1A1A1A"
      enableBackground="new 0 0 69 69"
      viewBox="0 0 69 69"
      className="dentist-chair-icon"
    >
      <path d="M37.824 24.317h.024a.75.75 0 00.726-.773l-.102-3.324c-.012-.413-.363-.71-.772-.727a.75.75 0 00-.727.774l.102 3.324a.75.75 0 00.75.726zM32.994 15.067l-3.324.102a.75.75 0 00.022 1.5h.023l3.325-.102a.75.75 0 00.727-.772c-.013-.414-.366-.698-.773-.728zM32.578 20.999a.75.75 0 001.06-.032l2.278-2.423a.75.75 0 10-1.093-1.027l-2.278 2.422a.75.75 0 00.033 1.06z"></path>
      <path d="M5.596 18.273v4.784c-1.156 1.606-.923 3.857.607 5.164l19.68 16.817a3.845 3.845 0 002.497.922h1.985l3.642 11.774h-2.296a4.492 4.492 0 00-4.485 4.489v.93c0 .413.336.75.75.75h20.686a.75.75 0 00.75-.75v-.93a4.492 4.492 0 00-4.486-4.489h-2.372l2.73-11.774h2.973c.555 0 1.094.198 1.518.557l10.393 8.83a3.842 3.842 0 005.42-.443 3.853 3.853 0 00-.444-5.422l-1.612-1.37V12.63c0-4.153-3.38-7.532-7.531-7.532h-5.142a10.109 10.109 0 00-7.337 3.172l-1.017 1.08a5.306 5.306 0 00-3.245-.994 5.33 5.33 0 00-3.744 1.687l-.617.656a2.332 2.332 0 00-.635 1.682c.02.629.28 1.21.736 1.639l4.396 4.135a2.345 2.345 0 001.608.636c.626 0 1.25-.248 1.711-.738l.619-.658c1.793-1.907 2.005-4.851.263-7.017l1.017-1.081a8.61 8.61 0 016.245-2.7h5.142a6.039 6.039 0 016.03 6.033v34.208l-9.013-7.66a3.851 3.851 0 00-2.488-.913h-4.41v-4.251h5.294a.75.75 0 000-1.5H39.326a.75.75 0 000 1.5h5.294v4.25H30.663c-.557 0-1.097-.2-1.522-.562L11.196 22.37a3.794 3.794 0 00-4.1-.56v-3.536A3.851 3.851 0 0010.19 14.5v-4.438a3.85 3.85 0 00-3.845-3.847A3.85 3.85 0 002.5 10.063v4.438a3.851 3.851 0 003.095 3.772zm36.646-1.905l-.619.657a.864.864 0 01-1.2.037l-4.396-4.134c-.163-.155-.257-.365-.264-.593s.074-.444.228-.607l.617-.658a3.854 3.854 0 012.817-1.215c3.35 0 5.156 4.021 2.817 6.513zm2.684 42.866a2.99 2.99 0 012.986 2.989v.18H28.726v-.18a2.99 2.99 0 012.985-2.989c11.022.053 11.465 0 13.215 0zm-3.913-1.5h-5.436l-3.641-11.773h11.808l-2.73 11.773zM10.222 23.51l17.945 15.333a3.845 3.845 0 002.496.922H50.53c.555 0 1.094.198 1.517.556l12.126 10.304a2.353 2.353 0 01.27 3.31c-.849 1-2.328 1.099-3.305.27l-10.392-8.832a3.851 3.851 0 00-2.489-.913H28.38c-.557 0-1.098-.2-1.522-.563L7.178 27.08a2.35 2.35 0 01-.26-3.31 2.341 2.341 0 013.304-.26zm-6.22-13.447a2.349 2.349 0 012.344-2.347 2.35 2.35 0 012.345 2.347v4.438c0 1.295-1.052 2.348-2.345 2.348S4 15.796 4 14.5v-4.438z"></path>
    </svg>
  );
};

export default DentistChair;
