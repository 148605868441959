import React from "react";
import Fruit from "../../../../assets/icons/printables/Fruit";
import Spreadsheet from "../../../../assets/icons/printables/Spreadsheet";
import ToothbrushCircle from "../../../../assets/icons/printables/ToothbrushCircle";
import "./PatientEducation.css";

const Printables = () => {
  return (
    <div className="printables subpage">
      <header className="printables-header subpage-header">
        <h1>Patient Education</h1>
      </header>
      <section className="welcome">
        <article>
          <p>
            Here are resources to keep your dental health journey moving
            forward!
          </p>
        </article>
      </section>
      <section className="subpage-content printables-content">
        <a
          href="/sunnyside_smart_snack_guide.pdf"
          alt="Smart Snack Guide"
          target="_blank"
          rel="noreferrer noopener"
        >
          <button>
            <Fruit />
            <h4>Snack Guide</h4>
          </button>
        </a>
        <a
          href="/sunnyside_brushing_chart.pdf"
          alt="Brushing Chart"
          target="_blank"
          rel="noreferrer noopener"
        >
          <button>
            <Spreadsheet />
            <h4>Brush Chart</h4>
          </button>
        </a>
        <a
          href="/sunnyside_better_brushing_brochure.pdf"
          alt="Toddler Brushing Tips"
          target="_blank"
          rel="noreferrer noopener"
        >
          <button>
            <ToothbrushCircle />
            <h4>Toddler Tips</h4>
          </button>
        </a>
      </section>
    </div>
  );
};

export default Printables;
