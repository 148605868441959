import React from "react";

const Ambulance = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      x="0"
      y="0"
      fill="#1A1A1A"
      viewBox="0 0 512 512"
      className="ambulance-icon"
    >
      <path d="M466.227 263.61l-38.707-10.352-31.586-96.031a21.232 21.232 0 00-20.207-14.625h-26.309v-18a30 30 0 00-60 0v18H51.27A21.299 21.299 0 0030 163.875v188.688a21.299 21.299 0 0021.27 21.273h32.369a49.52 49.52 0 0098.32 0h165.68a49.52 49.52 0 0098.32 0h14.771A21.299 21.299 0 00482 352.562v-68.398a21.309 21.309 0 00-15.773-20.555zM301.418 124.601a18 18 0 0136 0v18h-36zM132.801 405.398a37.563 37.563 0 1137.558-37.562 37.606 37.606 0 01-37.558 37.562zm264 0a37.563 37.563 0 1137.558-37.562 37.606 37.606 0 01-37.558 37.562zM470 352.562a9.28 9.28 0 01-9.27 9.274H445.96a49.52 49.52 0 00-98.32 0h-165.68a49.52 49.52 0 00-98.32 0H51.27a9.28 9.28 0 01-9.27-9.274V163.875a9.28 9.28 0 019.27-9.273h324.457a9.258 9.258 0 018.808 6.375l32.602 99.117a6.002 6.002 0 004.148 3.922l41.84 11.187a9.289 9.289 0 016.875 8.961z"></path>
      <path d="M202.191 204.086h-20.863v-20.867a11.45 11.45 0 00-11.433-11.438h-24.372a11.45 11.45 0 00-11.433 11.438v20.867h-20.867a11.443 11.443 0 00-11.434 11.43v24.367a11.45 11.45 0 0011.434 11.437h20.867v20.867a11.443 11.443 0 0011.434 11.43h24.37a11.443 11.443 0 0011.434-11.43V251.32h20.864a11.45 11.45 0 0011.433-11.438v-24.366a11.443 11.443 0 00-11.434-11.43zm-26.863 35.234a6.002 6.002 0 00-6 6v26.297H146.09V245.32a6.002 6.002 0 00-6-6l-26.3.563v-23.797h26.3a6.002 6.002 0 006-6l-.566-26.305 23.804-.562v26.867a6.002 6.002 0 006 6h26.309l.555 23.234zM376.625 168.64a6 6 0 00-5.7-4.124h-91.507a6.002 6.002 0 00-6 6v87.703a6.002 6.002 0 006 6H399.77a6.003 6.003 0 005.7-7.875zm-91.207 83.579v-75.703h81.164l24.898 75.703z"></path>
    </svg>
  );
};

export default Ambulance;
