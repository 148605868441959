import React from "react";
import DentistOfficeIcon from "../../assets/icons/footer/DentistOfficeIcon";
import PhoneIcon from "../../assets/icons/footer/PhoneIcon";
import TextIcon from "../../assets/icons/TextIcon";
import FaxIcon from "../../assets/icons/footer/FaxIcon";
import FacebookIcon from "../../assets/icons/social/FacebookIcon";
import InstagramIcon from "../../assets/icons/social/InstagramIcon";
import GoogleIcon from "../../assets/icons/social/GoogleIcon";
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <section className="address">
        <DentistOfficeIcon />
        <h4>Sunnyside Pediatric Dentistry</h4>
        <address>
          1721 Fifth Ave. Suite A <br /> San Rafael, CA 94901
        </address>
      </section>
      <section className="phone">
        <div className="telephone">
          <a href="tel:4157853445">
            <PhoneIcon />
          </a>
          <h4>Phone</h4>
          <p>
            <a href="tel:4157853445">415-785-3445</a>
          </p>
        </div>
        <div className="telephone">
          <a href="sms:4157853445">
            <TextIcon />
          </a>
          <h4>Text</h4>
          <p>
            <a href="sms:4157853445">415-785-3445</a>
          </p>
        </div>
        <div className="fax">
          <FaxIcon />
          <h4>Fax</h4>
          <p>415-785-3445</p>
        </div>
      </section>
      <section className="contact">
        <div>
          <a
            href="https://facebook.com/sunnysidedentistry"
            target="_blank"
            rel="noreferrer noopener"
          >
            <FacebookIcon />
          </a>
          <a
            href="https://www.instagram.com/sunnysidedentistry/"
            target="_blank"
            rel="noreferrer noopener"
          >
            <InstagramIcon />
          </a>
          <a
            href="https://search.google.com/local/reviews?placeid=ChIJ981Wb1iXhYARgKfk3ML2DSU&q=*&hl=en&gl=IT"
            target="_blank"
            rel="noreferrer noopener"
          >
            <GoogleIcon />
          </a>
        </div>
        <div>
          <h4>Email</h4>
          <p>
            <a
              href="mailto:hello@sunnysidesmiles.com"
              target="_blank"
              rel="noreferrer noopener"
            >
              hello@sunnysidesmiles.com
            </a>
          </p>
        </div>
      </section>
    </footer>
  );
};

export default Footer;
