import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import KidToothbrushSmile from "../../../../assets/sidebar/kid-toothbrush-smile.jpg";
import "./NewPatients.css";

const NewPatients = () => {
  const scrollWidthOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -120;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };
  return (
    <div className="new-patients subpage">
      <header className="new-patients-header subpage-header">
        <h1>New Patients</h1>
      </header>
      <section className="subpage-content new-patients-content">
        <div className="new-patients-buttons">
          <HashLink to="/contact#contact-form" scroll={scrollWidthOffset}>
            <button>Request A Consultation</button>
          </HashLink>
        </div>
        <article className="new-patient-experience">
          <header>
            <h2>A Unique Two-Part Patient Experience</h2>
            <p>
              Whether your child is new to the dentist, or has been many times,
              our goal for your family to have a positive introduction to what
              we hope will be a long relationship between your family and the
              team at Sunnyside Pediatric Dentistry. As your new pediatric
              dental home, we want to be here to help your children grow up with
              strong happy and healthy smiles.
            </p>
          </header>
          <br />
          <h3>
            Step 1: <br /> Initial Consulation - Phone Call or Video Chat with
            Dr. Sophie
          </h3>
          <p>
            ​All new families to the practice begin with a complimentary initial
            virtual consultation. You can schedule phone call, or video chat. If
            your child is nervous about coming to the dentist we highly
            recommend a video chat at a time when they can be present. During
            this session, we will:
          </p>
          <ol>
            <li>Review Medical & Dental Histories</li>
            <li>Review any records from a previous dental office</li>
            <li>
              Discuss any specific concerns you may have about your child's oral
              health
            </li>
            <li>
              Begin creating goals for establishing your dental home with our
              office
            </li>
          </ol>
          <br />
          <h3>
            Step 2:
            <br /> Comprehensive Exam Visit
          </h3>
          <p>
            In order to establish care with our practice, all patients must
            complete a comprehensive new patient exam. Even if they were
            recently seen by another provider Dr. Sophie will perform her own
            examination and treatment planning. During the comprehensive exam
            visit, the goal is complete the following:
          </p>
          <ol>
            <li>A thorough visual examination</li>
            <li>X-rays if indicated</li>
            <li>Intra-oral photographs if indicated</li>
            <li>A thorough cleaning</li>
            <li>Fluoride Varnish if recommended</li>
          </ol>
          <p>
            Given the distinct personalities of each child, there are times when
            several shorter focused visits are more successful. By working with
            each family individually to develop a long term patient care plan
            strategy, we can help to guide the positive behaviors that build the
            trust, confidence and cooperation that each child needs to have a
            great patient experience and a healthy happy smile
          </p>
        </article>
        <img
          src={KidToothbrushSmile}
          alt="Happy kid with toothbrush"
          className="kid-toothbrush-smile side-img"
        />

        <article className="first-visit">
          <header>
            <h2>Preparing for your Consultation & First Visit</h2>
          </header>
          <p>Your first visit begins at home!</p>
          <p>
            Before your consultation, please complete the online patient forms
            so that we can gather valuable information about your family and
            your child. With this information we will begin to create family
            centered goals for your experience with Sunnyside Pediatric
            Dentistry as well as a customized behavior guidance strategy that
            will help encourage a successful first visit!
          </p>
          <p>
            *Please be sure to forward all previous dental records as well if
            applicable (X-Rays, Photos & Treatment Record)
          </p>
        </article>
      </section>
    </div>
  );
};

export default NewPatients;
