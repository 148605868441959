import React from "react";
import { Link } from "react-router-dom";
import DrSophieTools from "../../../../assets/dr-sophie/dr-sophie-tools.jpg";
import "./DrSophieHome.css";

const DrSophieHome = () => {
  return (
    <>
      <section className="dr-sophie-home">
        <article className="dr-sophie-home-article">
          <img
            src={DrSophieTools}
            alt="Dr Sophie with a toothbrush"
            className="dr-sophie-tools"
          />
          <div className="dr-sophie-home-title-p">
            <header>
              <h2 className="dr-sophie-home-title">
                Meet
                <br />
                <span>Dr. Sophie</span>
              </h2>
            </header>
            <p className="dr-sophie-home-p">
              Hello, my name is Dr. Sophie and I LOVE being a pediatric dentist!
              Getting families excited about oral health makes my day. ​I have
              all the most fun dentist toys to help keep your child smiling, so
              contact me today to set up your first visit!
            </p>
            <div className="dr-sophie-home-btn-wrapper">
              <Link to="/dr-sophie" className="dr-sophie-home-a">
                <button className="dr-sophie-home-button">
                  More about Dr. Sophie
                </button>
              </Link>
            </div>
          </div>
        </article>
      </section>
      {/* <WavyDown /> */}
    </>
  );
};

export default DrSophieHome;
