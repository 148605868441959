import React from "react";
import DrSophieHands from "../../../../assets/sidebar/dr-sophie-funny.jpg";
import "./FAQ.css";

const FAQ = () => {
  return (
    <div className="faq subpage">
      <header className="faq-header subpage-header">
        <h1>Frequently Asked Questions</h1>
      </header>
      <section className="welcome">
        <article>
          <p>
            Finding the right dentist for your child can be a challenge. Here
            are some frequently asked questions many parents have about
            pediatric oral health. ​If you have additional questions, give us a
            call or submit a question online on our Ask Dr. Sophie page.
          </p>
        </article>
      </section>
      <section className="subpage-content faq-content">
        <article className="what-is-p-dentist">
          <header>
            <h2>What is a Pediatric Dentist?</h2>
          </header>
          <p>
            Pediatric dentists are the pediatricians of dentistry. A pediatric
            dentist has two to three years specialty training following dental
            school and limits his/her practice to treating children only.
            Pediatric dentists are primary and specialty oral care providers for
            infants and children through adolescence, including those with
            special health needs.
          </p>
        </article>
        <img
          src={DrSophieHands}
          alt="Dr Sophie posing"
          className="dr-sophie-funny side-img"
        />
        <article>
          <header>
            <h2>When should my baby start going to the Dentist?</h2>
          </header>
          <p>
            Both the Academy of Pediatric Dentistry and the American Academy of
            Pediatrics Recommends that all children visit a dentist when the
            first tooth erupts, or no later than their first birthday. Early
            preventive dental care has been shown to decrease future caries
            experience.
          </p>
        </article>
        <article>
          <header>
            <h2>Do you cater to kids with special health needs?</h2>
          </header>
          <p>
            In addition to 2 years of pediatric specialty training, Dr. Sophie
            spent a year in general residency with a focus on treating patients
            with complex medical histories and special needs. When renovating
            the office, we made sure to update the doorways, flooring,
            handrails, ramp and bathroom to accommodate patients in wheelchairs.
            Additionally we have an exam room without a dental chair to help
            even the most nervous patients feel more comfortable. We also
            suggest that you consider bringing a toy, sunglasses or wear a
            favorite item of clothing to wear to their first visit.
          </p>
        </article>
        {/* <img src={Selfie} alt="Selfie" className="selfie side-img" /> */}
        <article>
          <header>
            <h2>How often does my child need to see the Pediatric Dentist?</h2>
          </header>
          <p>
            Periodic Oral Evaluations are generally recommended every 3-6 months
            in order prevent cavities and other dental problems. Dr. Sophie will
            evaluate each patient's individual risk factors and establish a
            re-care frequency recommendation based on their personal oral
            health.
          </p>
        </article>
      </section>
      <footer className="faq-footer">
        <p>
          For a complete list of dental topics and more FAQ visit the American
          Academy of Pediatric Dentistry's sponsored website{" "}
          <a
            href="http://www.mychildrensteeth.org"
            target="_blank"
            rel="noreferrer noopener"
          >
            www.mychildrensteeth.org
          </a>
          .
        </p>
      </footer>
    </div>
  );
};

export default FAQ;
