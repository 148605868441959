import React from "react";

const Facebook = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 310 310"
      version="1.1"
      viewBox="0 0 310 310"
      xmlSpace="preserve"
      className="facebook-icon"
    >
      <g>
        <path d="M81.703 165.106h33.981V305a5 5 0 005 5H178.3a5 5 0 005-5V165.765h39.064a5 5 0 004.967-4.429l5.933-51.502a5 5 0 00-4.966-5.572h-44.996V71.978c0-9.732 5.24-14.667 15.576-14.667h29.42a5 5 0 005-5V5.037a5 5 0 00-5-5h-40.545A39.746 39.746 0 00185.896 0c-7.035 0-31.488 1.381-50.804 19.151-21.402 19.692-18.427 43.27-17.716 47.358v37.752H81.703a5 5 0 00-5 5v50.844a5 5 0 005 5.001z"></path>
      </g>
    </svg>
  );
};

export default Facebook;
