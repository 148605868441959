import React, { Component } from "react";
import "./ReviewsHome.css";

class ReviewsHome extends Component {
  state = {};
  componentDidMount() {
    this.startShow = setInterval(this.changeSlide, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.startShow);
  }
  render() {
    let currentSlide = 0;
    this.changeSlide = () => {
      const slides = document.querySelectorAll(".slide");

      slides[currentSlide].classList = "slide";
      currentSlide = (currentSlide + 1) % slides.length;
      slides[currentSlide].classList = "slide showing";
    };

    return (
      <section className="reviews-home">
        <ul className="reviews-slideshow">
          <li className="slide showing">
            "When I tell my daughter we are going to the dentist she gets
            excited." <br />
            <span>-Aimee L.</span>
          </li>
          <li className="slide">
            "The end of each visit is met with a treasure trove of goodies that
            my kids go gaga for." <br />
            <span>-Amanda M.</span>
          </li>
          <li className="slide">
            "The visit exceeded my expectations and I'm looking forward to the
            next one." <br />
            <span>-Jiri H.</span>
          </li>
          <li className="slide">
            "They really take their time during each appointment and make the
            kids super comfortable." <br />
            <span>-Jenny B.</span>
          </li>
          <li className="slide">
            "Dr Sophie is amazing, both of my kids adore her!" <br />
            <span>-Linda S.</span>
          </li>
        </ul>
        <span className="google-reviews">
          <a
            href="https://search.google.com/local/reviews?placeid=ChIJ981Wb1iXhYARgKfk3ML2DSU&q=*&hl=en&gl=IT"
            target="_blank"
            rel="noreferrer noopener"
          >
            Google
          </a>
        </span>
      </section>
    );
  }
}

export default ReviewsHome;
