import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import "./OurServicesHome.css";

const OurServicesHome = () => {
  const scrollWidthOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -100;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };
  return (
    <section className="smile-services-home">
      <article className="services-home-article">
        <header className="services-home-header">
          <h2>Our Services</h2>
          <Link to="/dental-services" id="services-btn-wide">
            <button className="learn-more-btn">Learn More</button>
          </Link>
        </header>
        <ul className="services-home-list">
          <li>
            <HashLink
              to="/dental-services#initial-consult"
              scroll={scrollWidthOffset}
            >
              Virtual Initial Consultations
            </HashLink>
          </li>
          <li>
            <HashLink
              to="/dental-services#preventive"
              scroll={scrollWidthOffset}
            >
              Preventive Dental Care
            </HashLink>
          </li>
          <li>
            <HashLink to="/dental-services#infant" scroll={scrollWidthOffset}>
              Infant Oral Healthcare
            </HashLink>
          </li>
          <li>
            <HashLink to="/dental-services#special" scroll={scrollWidthOffset}>
              Patients with Special Healthcare Needs
            </HashLink>
          </li>
          <li>
            <HashLink
              to="/dental-services#minimally-invasive"
              scroll={scrollWidthOffset}
            >
              Minimally Invasive Dentistry
            </HashLink>
          </li>
          <li>
            <HashLink
              to="/dental-services#surgical-restorative"
              scroll={scrollWidthOffset}
            >
              Surgical / Restorative Dentistry
            </HashLink>
          </li>
          <li>
            <HashLink
              to="/dental-services#anxiety-reduction"
              scroll={scrollWidthOffset}
            >
              Non-Pharmacological Behavior Guidance & Anxiety Reduction
              Strategies
            </HashLink>
          </li>
          <li>
            <HashLink
              to="/dental-services#anesthesia"
              scroll={scrollWidthOffset}
            >
              Nitrous Oxide + Local Anesthesia
            </HashLink>
          </li>
          <li>
            <HashLink to="/dental-services#sedation" scroll={scrollWidthOffset}>
              Sedation / General Anesthesia at a Surgery Center
            </HashLink>
          </li>
          <li>
            <HashLink
              to="/dental-services#emergency-care"
              scroll={scrollWidthOffset}
            >
              Emergency Care
            </HashLink>
          </li>
        </ul>
        <Link to="/dental-services" id="services-btn-small">
          <button className="learn-more-btn">Learn More</button>
        </Link>
      </article>
      <article className="smile-club-home-article">
        <header className="smile-club-home-header">
          <h2>
            The
            <br /> Smile Club
          </h2>
        </header>
        <p>
          The Sunnyside Smile Club is an in house membership plan that offers 6
          months of basic preventive care services. It is intended for families
          who will not be using PPO dental benefits for reimbursement for their
          care at Sunnyside.
        </p>
      </article>
    </section>
  );
};

export default OurServicesHome;
