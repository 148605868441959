import React from "react";

const Baby = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      x="0"
      y="0"
      viewBox="0 0 100 100"
      id="baby"
    >
      <path d="M89.124 47.918h-.937c-5.515-14.93-19.999-25.03-36.664-25.611-4.864-.861-8.572-5.107-8.572-10.213 0-4.415 3.591-8.006 8.003-8.006 3.363 0 6.1 2.739 6.1 6.1a4.585 4.585 0 01-4.579 4.579 3.36 3.36 0 01-3.354-3.357 1.516 1.516 0 00-3.03 0c0 3.521 2.864 6.388 6.385 6.388 4.197 0 7.609-3.412 7.609-7.609 0-5.034-4.097-9.13-9.13-9.13-6.085 0-11.034 4.952-11.034 11.036 0 4.24 1.983 8.02 5.064 10.479-15.169 1.769-28.042 11.471-33.167 25.345h-.934C4.882 47.918 0 52.803 0 58.803c0 5.934 4.773 10.773 10.682 10.879C15.048 86.706 31.379 98.939 50 98.939s34.955-12.233 39.327-29.258c5.906-.105 10.679-4.945 10.679-10.879 0-6.002-4.885-10.884-10.882-10.884zm-.006 18.736h-.994a1.52 1.52 0 00-1.479 1.191C83.052 84.105 67.639 95.912 50 95.912c-17.639 0-33.048-11.807-36.643-28.066a1.514 1.514 0 00-1.479-1.191h-.994c-4.33 0-7.854-3.523-7.854-7.852 0-4.327 3.524-7.854 7.854-7.854h2.003c.652 0 1.23-.415 1.437-1.033C19.279 35.2 33.618 25.309 50 25.309c.432 0 .861.011 1.291.024.668.103 1.347.172 2.043.172.096 0 .189-.011.28-.028 14.846 1.335 27.473 10.816 32.062 24.438a1.516 1.516 0 001.437 1.033h2.006c4.33 0 7.852 3.524 7.852 7.854s-3.523 7.852-7.853 7.852z"></path>
      <circle cx="62.697" cy="64.648" r="3.66"></circle>
      <circle cx="38.7" cy="64.648" r="3.661"></circle>
      <path d="M59.715 78.457l-.591.367c-5.636 3.47-12.624 3.467-18.245.003l-.594-.366a1.52 1.52 0 00-2.084.494 1.518 1.518 0 00.494 2.084l.597.367c3.3 2.033 7 3.049 10.706 3.049 3.703 0 7.409-1.019 10.715-3.053l.595-.366a1.517 1.517 0 00.493-2.085 1.523 1.523 0 00-2.086-.494zM89.077 52.834v3c1.563 0 2.834 1.271 2.834 2.834s-1.271 2.833-2.834 2.833v3c3.217 0 5.834-2.616 5.834-5.833s-2.617-5.834-5.834-5.834zM5.173 58.668a5.84 5.84 0 005.834 5.833v-3c-1.563 0-2.834-1.271-2.834-2.833s1.271-2.834 2.834-2.834v-3a5.84 5.84 0 00-5.834 5.834z"></path>
    </svg>
  );
};

export default Baby;
