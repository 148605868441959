import React from "react";
import { Switch, Route } from "react-router-dom";
import "normalize.css";
import "@fontsource/quicksand";
import "@fontsource/handlee";
import "@fontsource/shadows-into-light-two";
import Layout from "./components/Layout";
import {
  Covid,
  Contact,
  FAQ,
  SmileClub,
  Insurance,
  NewPatients,
  PatientEducation,
  EmergencyCare,
  DentalServices,
  OfficeTour,
  MeetTheTeam,
  DrSophie,
  WhySunnyside,
  OurPhilosophy,
  Home,
} from "./components/index";
import "./App.css";

function App() {
  return (
    <div style={{ maxWidth: "1500px", margin: "auto" }}>
      <Layout>
        <Switch>
          <Route path="/covid-19" component={Covid} />
          <Route path="/contact" component={Contact} />
          <Route path="/faq" component={FAQ} />
          <Route path="/new-patients" component={NewPatients} />
          <Route path="/patient-education" component={PatientEducation} />
          <Route path="/emergency-care" component={EmergencyCare} />
          <Route path="/dental-services" component={DentalServices} />
          <Route path="/office-tour" component={OfficeTour} />
          <Route path="/meet-the-team" component={MeetTheTeam} />
          <Route path="/dr-sophie" component={DrSophie} />
          <Route path="/our-philosophy" component={OurPhilosophy} />
          <Route path="/why-sunnyside" component={WhySunnyside} />
          <Route path="/" component={Home} />
        </Switch>
      </Layout>
    </div>
  );
}

export default App;
