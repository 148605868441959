import React from "react";
const PPE = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      x="0"
      y="0"
      fill="#1A1A1A"
      enableBackground="new 0 0 100 100"
      viewBox="0 0 100 100"
      id="ppe"
    >
      <path d="M65.2 44.1H33.4c-.6 0-1 .4-1 1s.4 1 1 1h31.8c.6 0 1-.4 1-1s-.4-1-1-1zM65.2 53.7H33.4c-.6 0-1 .4-1 1s.4 1 1 1h31.8c.6 0 1-.4 1-1s-.4-1-1-1zM65.2 63.2H33.4c-.6 0-1 .4-1 1s.4 1 1 1h31.8c.6 0 1-.4 1-1s-.4-1-1-1z"></path>
      <path d="M84.8 37.5h-.3c-.2-.1-.4-.1-.6 0-.1 0-10.7 1.7-17.9-4.8-5.9-5.4-12-6.6-14.6-6.9-.1-.1-.3-.1-.4-.1H49.7c-.2 0-.3.1-.4.1-2.7.3-8.7 1.5-14.7 6.9-7.1 6.5-17.8 4.8-17.9 4.8-.3-.1-.5 0-.7.1-.2-.1-.3-.1-.5-.1C7.1 39 1 45.6 1 53.1c0 8.8 8.2 16 18.2 16 1.3 0 2.7-.1 4.1-.4.2 0 .4-.1.5-.3 5.9 6.6 14.4 10.1 25.5 10.4h1.8c11-.3 19.4-3.7 25.5-10.2.1.1.2.2.4.2 1.3.3 2.7.4 4.1.4 10.1 0 18.2-7.2 18.2-16 0-7.6-6.1-14.2-14.5-15.7zM3 53.1c0-6.4 5-11.9 12.1-13.4-.3 3.8-.4 17 7.3 27.1-1 .2-2.1.3-3.2.3-8.9 0-16.2-6.3-16.2-14zm72 13.4c-5.4 6.2-13.6 9.5-24.3 9.8h-.9-.2c-10.7-.2-18.8-3.5-24.3-9.8-8-9.1-8-22.2-7.8-26.3 3.4.3 12.2.3 18.7-5.6 5.7-5.2 11.3-6.1 13.5-6.3H50.6c2.2.2 7.8 1.2 13.5 6.3 6.6 6 15.4 5.9 18.7 5.6.2 4 .2 17.1-7.8 26.3zm6.1.7c-1 0-2-.1-3-.3 7.8-10.1 7.7-23.2 7.4-27.2 6.9 1.7 11.8 7.2 11.8 13.5 0 7.7-7.3 14-16.2 14z"></path>
    </svg>
  );
};

export default PPE;
