import React from "react";
import { HashLink } from "react-router-hash-link";
import Toothpaste from "../../../../assets/icons/Toothpaste";
import DentistChair from "../../../../assets/icons/DentistChair";
import TV from "../../../../assets/icons/TV";
import HappyDentist from "../../../../assets/icons/HappyDentist";
import ToothSmile from "../../../../assets/icons/ToothSmile";
import Baby from "../../../../assets/icons/Baby";
import Superhero from "../../../../assets/icons/Superhero";
import Anesthesia from "../../../../assets/icons/Anesthesia";
import Sedation from "../../../../assets/icons/Sedation";
import Ambulance from "../../../../assets/icons/Ambulance";
import "./DentalServices.css";

const DentalServices = () => {
  // Offset scrolling Y position to adjust for Navbar
  const scrollWidthOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -100;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };
  return (
    <div className="dental-services subpage">
      <header className="dental-services-header subpage-header">
        <h1>Dental Services</h1>
      </header>
      <section className="subpage-content dental-services-content">
        <ul className="services-list-menu">
          <li>
            <HashLink to="#initial-consult" scroll={scrollWidthOffset}>
              Virtual Initial Consultations
            </HashLink>{" "}
            <span>|</span>
          </li>{" "}
          <li>
            <HashLink to="#preventive" scroll={scrollWidthOffset}>
              Preventive Dental Care
            </HashLink>{" "}
            <span>|</span>
          </li>{" "}
          <li>
            <HashLink to="#infant" scroll={scrollWidthOffset}>
              Infant Oral Healthcare
            </HashLink>{" "}
            <span>|</span>
          </li>{" "}
          <li>
            <HashLink to="#special" scroll={scrollWidthOffset}>
              Patients with Special Healthcare Needs
            </HashLink>{" "}
            <span>|</span>
          </li>{" "}
          <li>
            <HashLink to="#minimally-invasive" scroll={scrollWidthOffset}>
              Minimally Invasive Dental Care
            </HashLink>{" "}
            <span>|</span>
          </li>{" "}
          <li>
            <HashLink to="#surgical-restorative" scroll={scrollWidthOffset}>
              Surgical/Restorative Dentistry
            </HashLink>{" "}
            <span>|</span>
          </li>{" "}
          <li>
            <HashLink to="#anxiety-reduction" scroll={scrollWidthOffset}>
              Non-Pharmacological Behavior Guidance & Anxiety Reduction
              Strategies
            </HashLink>{" "}
            <span>|</span>
          </li>{" "}
          <li>
            {" "}
            <HashLink to="#anesthesia" scroll={scrollWidthOffset}>
              Nitrous Oxide & Local Anesthesia
            </HashLink>{" "}
            <span>|</span>
          </li>{" "}
          <li>
            <HashLink to="#sedation" scroll={scrollWidthOffset}>
              Sedation/General Anesthesia at a Surgery Center
            </HashLink>{" "}
            <span>|</span>
          </li>{" "}
          <li>
            <HashLink to="#emergency-care" scroll={scrollWidthOffset}>
              Emergency Dental Care
            </HashLink>
          </li>
        </ul>
        <article id="initial-consult">
          <header>
            <h2>
              Virtual
              <br /> Initial Consultations
            </h2>
          </header>
          <div className="services-description">
            <HappyDentist />
            <p>
              During your free initial virtual consultation, your family will
              meet with Dr. Sophie via Zoom or a phone call. This virtual visit
              includes a review of medical/dental histories, and initial
              evaluation of hygiene and dietary habits so that when you come for
              your first visit we can focus on patient care. If your child is
              particularly nervous about their first visit please let us know.
            </p>
          </div>
        </article>
        <article id="preventive">
          <header>
            <h2>
              Preventive <br /> Dental Care
            </h2>
          </header>
          <div className="services-description">
            <ToothSmile />
            <p>
              The ADA and AAPD [American Dental Association + American Academy
              of Pediatric Dentistry] recommend that all children begin routine
              preventive dental care visits every 3-6 months when they get their
              first tooth, or by their first birthday. These visits generally
              include a comprehensive examination, preventive (prophylactic)
              dental cleaning, fluoride varnish (if recommended), Digital x-rays
              and intra-oral photos (if recommended), Oral Hygiene Education and
              Cavity Reduction Based Dietary Counseling.
            </p>
          </div>
        </article>
        <article id="infant">
          <header>
            <h2>
              Infant <br /> Oral Healthcare
            </h2>
          </header>
          <div className="services-description">
            <Baby />
            <p>
              When you child starts getting teeth you will likely have many
              questions about how to best care for them. Who best to answer
              those questions that a Pediatric Dentist? Dr. Sophie is specially
              trained and has extensive experience helping parents just like you
              navigate the complex world of pediatric oral health!
              <br /> <br />
              All children should establish their Dental Home within 6 months of
              when their first tooth comes in or by their first birthday, even
              if they don’t have any teeth yet. Children who establish their
              dental home early have better overall oral health outcomes. It
              also helps to promote a positive experience with dental care.
            </p>
          </div>
        </article>
        <article id="special">
          <header>
            <h2>
              Patients with <br /> Special Healthcare Needs
            </h2>
          </header>
          <div className="services-description">
            <Superhero />
            <p>
              Our patient centered model encourages kids with a variety of
              adaptational needs to excel at the dentist. Dr Sophie completed an
              additional year or hospital training in the care of patients with
              complex medical, social and dental care needs. She has lots of
              experience developing child specific adaptations to her practice
              to provide appropriate care in a safe, compassionate and
              supportive way.
            </p>
          </div>
        </article>
        <article id="minimally-invasive">
          <header>
            <h2>
              Minimally <br />
              Invasive Dental Care
            </h2>
          </header>
          <div className="services-description">
            <Toothpaste />
            <p>
              Also known as the Medical Approach to the Management of Dental
              Caries (ie: decay), this is “No Numbing, No Drilling” a method of
              treating the decay without traditional invasive surgical dental
              treatments (ie: fillings/crowns). Studies show at least similar,
              and often improved long term outcomes with this approach. Silver
              Diamine Fluoride, Glass Ionomer Fillings/Crown, and Hall Style
              Stainless Steel Crowns are a treatment option for most patients
              that I see. For some patients, this type of approach can be used
              in combination with traditional surgical dental treatments, or as
              a stepping stone to help develop trust, behavior and ultimately
              improved cooperation.
            </p>
          </div>
        </article>
        <article id="surgical-restorative">
          <header>
            <h2>
              Surgical / <br /> Restorative Dentistry
            </h2>
          </header>
          <div className="services-description">
            <DentistChair />
            <p>
              Sometimes traditional fillings and crowns are the recommended care
              to restore health, form and function.
            </p>
          </div>
        </article>
        <article id="anxiety-reduction">
          <header>
            <h2>
              Non-Pharmacological <br /> Behavior Guidance & <br /> Anxiety
              Reduction Strategies
            </h2>
          </header>
          <div className="services-description">
            <TV />
            <p>
              In order to help improve trust, comfort and cooperation for dental
              visits, we offer various non-pharmacological behavior guidance and
              anxiety reducing strategies in the office. These include, the
              “tell-show-do” approach to treatment, TVs on the ceiling above the
              treatment chairs, weighted blankets, “buzzy eggs” (aka
              Thera-Tappers), sugar free lollipops, and a wide variety of fun
              prizes/oral hygiene tools to take home. Dr. Sophie believes in the
              “friends first” approach to care, and always tries to sandwich
              challenging parts of the visit with fun.
            </p>
          </div>
        </article>
        <article id="anesthesia">
          <header>
            <h2>
              Nitrous Oxide <br /> & Local Anesthesia
            </h2>
          </header>
          <div className="services-description">
            <Anesthesia />
            <p>
              When the treatment needed will require Local Anesthesia (ie: an
              injection){" "}
              <a
                href="https://www.ada.org/en/member-center/oral-health-topics/nitrous-oxide"
                target="_blank"
                rel="noreferrer"
              >
                Nitrous Oxide
              </a>{" "}
              ,also known as “laughing gas” can make it much easier for many
              children. Nitrous Oxide is a colorless, slightly sweet smelling
              gas that when administered at low doses helps to reduce anxiety,
              gag reflex, pain threshold and altered perception of time. Dr.
              Sophie often uses Inhalational Nitrous Oxide Analgesia in
              conjunction with a Topical Anesthetic before providing the Local
              Anesthesia, often using the “magic wand” automated anesthesia
              system which dramatically reduces the pain of injection. The most
              common side effect is nausea, but that is rare when patients do
              not eat for 2 hours prior to the visit.
            </p>
          </div>
        </article>
        <article id="sedation">
          <header>
            <h2>
              Sedation / General <br /> Anesthesia at a Surgery Center
            </h2>
          </header>
          <div className="services-description">
            <Sedation />
            <p>
              We always encourage kids to be brave and work with them to develop
              a behavior guidance strategy to help them be the best patient they
              can be. Our goal is to provide care for our patients in the office
              without sedation or general anesthesia. However there are times
              when, for the safety and well-being of the child, sedation/general
              anesthesia is needed to provide necessary dental care.
              <br /> <br />
              ​To provide our patients with the highest quality anesthesia
              services Dr. Sophie has access to the{" "}
              <a
                href="https://www.pdisurgerycenter.org/"
                target="_blank"
                rel="noreferrer"
              >
                PDI surgery center in Windsor, CA.
              </a>{" "}
              This surgery center is specially designed for dental patients.
              Their top notch anesthesiology team has extensive training and
              experience, having treated over 8,000 children in the last 12
              years.
            </p>
          </div>
        </article>
        <article id="emergency-care">
          <header>
            <h2>
              Emergency <br /> Dental Care
            </h2>
          </header>
          <div className="services-description">
            <Ambulance />
            <p>
              Dr. Sophie is available to her established patients for emergency
              evaluation and care as needed. Even if you are not a patient of
              record, we might be able to help. Please call the office, and
              press option 2, you will be directed to Dr. Sophie’s emergency
              care voicemail and provided with information on how to reach her
              directly if needed. Many concerns can be managed with a virtual
              visit, but if an in-person visit is needed, even after hours, that
              can be arranged. Visit our Emergency Dental Care page for more
              information.
            </p>
          </div>
        </article>
      </section>
    </div>
  );
};

export default DentalServices;
