import React from "react";

const Infection = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      x="0"
      y="0"
      fill="#FF3434"
      enableBackground="new 0 0 70 70"
      viewBox="0 0 70 70"
      id="infection"
    >
      <path d="M58.3 11.7C52.1 5.4 43.8 2 35 2s-17.1 3.4-23.3 9.7C5.4 17.9 2 26.2 2 35s3.4 17.1 9.7 23.3C17.9 64.6 26.2 68 35 68s17.1-3.4 23.3-9.7C64.6 52.1 68 43.8 68 35s-3.4-17.1-9.7-23.3zm-45.2 1.4C18.9 7.2 26.7 4 35 4c7.9 0 15.4 3 21.2 8.4L42.5 26.1c-1.8-1.5-4.1-2.5-6.6-2.7v-7.6c1.4-.4 2.4-1.7 2.4-3.2 0-1.9-1.5-3.4-3.4-3.4s-3.4 1.5-3.4 3.4c0 1.5 1 2.8 2.4 3.2v7.6c-2.5.2-4.7 1.2-6.5 2.7l-3.5-3.5c1.2-1.8 1-4.3-.6-5.9-1.9-1.9-4.9-1.9-6.7 0-1.9 1.9-1.9 4.9 0 6.7.9.9 2.1 1.4 3.4 1.4.9 0 1.8-.3 2.6-.8l3.5 3.5c-1.5 1.8-2.5 4.1-2.7 6.5h-7.6c-.4-1.4-1.7-2.4-3.2-2.4-1.9 0-3.4 1.5-3.4 3.4s1.5 3.4 3.4 3.4c1.5 0 2.8-1 3.2-2.4h7.6c.2 2.5 1.2 4.8 2.7 6.6L12.4 56.2C7 50.4 4 42.9 4 35c0-8.3 3.2-16.1 9.1-21.9zM44.7 35c0 5.3-4.4 9.7-9.7 9.7-2.3 0-4.4-.8-6.1-2.2l13.7-13.7c1.3 1.8 2.1 3.9 2.1 6.2zM35 54.4c1.2 0 2.2 1 2.2 2.2s-1 2.2-2.2 2.2-2.2-1-2.2-2.2 1-2.2 2.2-2.2zM25.3 35c0-2.7 1.1-5.1 2.8-6.8 1.8-1.7 4.2-2.8 6.8-2.8 2.3 0 4.4.8 6.1 2.2L27.4 41.1c-1.3-1.6-2.1-3.8-2.1-6.1zM35 13.9c-.8 0-1.4-.6-1.4-1.4s.6-1.4 1.4-1.4 1.4.6 1.4 1.4-.6 1.4-1.4 1.4zm-13.1 8c-1.1 1.1-2.8 1.1-3.9 0s-1.1-2.8 0-3.9c.5-.5 1.2-.8 1.9-.8s1.4.3 1.9.8c1.2 1.1 1.2 2.8.1 3.9zm-8 13.1c0 .8-.6 1.4-1.4 1.4s-1.4-.6-1.4-1.4.6-1.4 1.4-1.4 1.4.6 1.4 1.4zm43 21.9C51.1 62.8 43.3 66 35 66c-7.9 0-15.4-3-21.2-8.4L27.4 44c1.8 1.5 4.1 2.5 6.5 2.7v5.9c-1.8.5-3.2 2.1-3.2 4.1 0 2.3 1.9 4.2 4.2 4.2 2.3 0 4.2-1.9 4.2-4.2 0-2-1.4-3.6-3.2-4.1v-5.9c2.5-.2 4.7-1.2 6.5-2.7l5.3 5.3c-.7 1.3-.5 2.9.6 4 .7.7 1.5 1 2.4 1 .9 0 1.7-.3 2.4-1 1.3-1.3 1.3-3.5 0-4.8-1.1-1.1-2.7-1.3-4-.6l-5.3-5.3c1.5-1.8 2.5-4.1 2.7-6.5h6.7c.5 1.8 2.1 3.2 4.1 3.2 2.3 0 4.2-1.9 4.2-4.2s-1.9-4.2-4.2-4.2c-2 0-3.6 1.4-4.1 3.2h-6.7c-.2-2.5-1.2-4.7-2.7-6.5l13.7-13.7C63 19.6 66 27.1 66 35c0 8.3-3.2 16.1-9.1 21.9zm-6-7.4c.4 0 .7.1 1 .4.6.6.6 1.4 0 2-.6.6-1.4.5-2 0-.6-.6-.6-1.4 0-2 .2-.3.6-.4 1-.4zM55.2 35c0-1.2 1-2.2 2.2-2.2s2.2 1 2.2 2.2-1 2.2-2.2 2.2-2.2-1-2.2-2.2z"></path>
    </svg>
  );
};

export default Infection;
