import React from "react";

const FaxIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      x="0"
      y="0"
      viewBox="0 0 32 32"
      id="fax-icon"
    >
      <g data-name="Layer 24">
        <path d="M26.5 11h-13a1.5 1.5 0 00-1.5 1.5v1a1.5 1.5 0 001.5 1.5h13a1.5 1.5 0 001.5-1.5v-1a1.5 1.5 0 00-1.5-1.5zm.5 2.5a.5.5 0 01-.5.5h-13a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h13a.5.5 0 01.5.5zM16 24h-1a1 1 0 00-1 1v1a1 1 0 001 1h1a1 1 0 001-1v-1a1 1 0 00-1-1zm0 2h-1v-1h1zM20 24h-1a1 1 0 00-1 1v1a1 1 0 001 1h1a1 1 0 001-1v-1a1 1 0 00-1-1zm0 2h-1v-1h1zM24 24h-1a1 1 0 00-1 1v1a1 1 0 001 1h1a1 1 0 001-1v-1a1 1 0 00-1-1zm0 2h-1v-1h1zM16 20h-1a1 1 0 00-1 1v1a1 1 0 001 1h1a1 1 0 001-1v-1a1 1 0 00-1-1zm0 2h-1v-1h1zM20 20h-1a1 1 0 00-1 1v1a1 1 0 001 1h1a1 1 0 001-1v-1a1 1 0 00-1-1zm0 2h-1v-1h1zM24 20h-1a1 1 0 00-1 1v1a1 1 0 001 1h1a1 1 0 001-1v-1a1 1 0 00-1-1zm0 2h-1v-1h1zM16 16h-1a1 1 0 00-1 1v1a1 1 0 001 1h1a1 1 0 001-1v-1a1 1 0 00-1-1zm0 2h-1v-1h1zM20 16h-1a1 1 0 00-1 1v1a1 1 0 001 1h1a1 1 0 001-1v-1a1 1 0 00-1-1zm0 2h-1v-1h1zM24 16h-1a1 1 0 00-1 1v1a1 1 0 001 1h1a1 1 0 001-1v-1a1 1 0 00-1-1zm0 2h-1v-1h1z"></path>
        <path d="M27.5 9H27V3.5A1.5 1.5 0 0025.5 2h-12A1.5 1.5 0 0012 3.5V9h-1.4a3.46 3.46 0 00-3.1-2h-2A3.58 3.58 0 002 10.64v15.72A3.58 3.58 0 005.5 30h2a3.35 3.35 0 002.38-1H27.5a2.5 2.5 0 002.5-2.5v-15A2.5 2.5 0 0027.5 9zM13 3.5a.5.5 0 01.5-.5h12a.5.5 0 01.5.5V9H13zM5.5 29A2.58 2.58 0 013 26.36V10.64A2.58 2.58 0 015.5 8h2a2.44 2.44 0 012 1l.4.77a2.49 2.49 0 01.15.86v15.73a2.67 2.67 0 01-.62 1.73 2.26 2.26 0 01-1.91.91h-2zM29 26.5a1.5 1.5 0 01-1.5 1.5H10.62v-.13a3.65 3.65 0 00.18-.52c0-.07 0-.15.06-.22a3.76 3.76 0 00.09-.77V10.64a3.69 3.69 0 00-.06-.63H27.5A1.5 1.5 0 0129 11.5z"></path>
        <path d="M23.5 6h-8a.5.5 0 000 1h8a.5.5 0 000-1zM24.5 4h-10a.5.5 0 000 1h10a.5.5 0 000-1z"></path>
      </g>
    </svg>
  );
};

export default FaxIcon;
