import React from "react";

const Wavy = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1440 320"
      className="wavy-up"
    >
      <path
        fill="#09F"
        fillOpacity="1"
        d="M0,192L40,197.3C80,203,160,213,240,213.3C320,213,400,203,480,202.7C560,203,640,213,720,224C800,235,880,245,960,240C1040,235,1120,213,1200,208C1280,203,1360,213,1400,218.7L1440,224L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"
      ></path>
    </svg>
  );
};

export default Wavy;
