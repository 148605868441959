import React from "react";

const DentistOfficeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      x="0"
      y="0"
      enableBackground="new 0 0 64 64"
      viewBox="0 0 64 64"
      id="dentist-office-icon"
    >
      <path
        fill="white"
        stroke="#000"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M32 11l-2.015-1.344A3.91 3.91 0 0027.817 9h0a3.906 3.906 0 00-3.707 2.672h0a6.694 6.694 0 00.995 6.134L26 19l-.595 4.761c-.122.978 1.096 1.525 1.745.783l4.098-4.683a1 1 0 011.505 0l4.098 4.683c.649.742 1.867.196 1.745-.783L38 19l.895-1.194a6.695 6.695 0 00.995-6.134h0A3.906 3.906 0 0036.183 9h0c-.772 0-1.526.228-2.168.656L32 11zM41.789 27c-2.525 2.472-5.977 4-9.789 4s-7.264-1.528-9.789-4H6v34h52V27H41.789z"
      ></path>
      <g
        fill="orange"
        stroke="#000"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
      >
        <path d="M18 17c0-2.553.694-4.939 1.888-7H3v17h19.211A13.95 13.95 0 0118 17zM44.112 10A13.915 13.915 0 0146 17a13.95 13.95 0 01-4.211 10H61V10H44.112z"></path>
      </g>
      <path
        fill="none"
        stroke="#000"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M18 43H46V47H18z"
      ></path>
      <path
        fill="none"
        stroke="#000"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M21 47H43V61H21z"
      ></path>
      <circle
        cx="32"
        cy="17"
        r="14"
        fill="lightskyblue"
        stroke="#000"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
      ></circle>
      <path
        fill="white"
        stroke="#000"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M32 11l-2.015-1.344A3.91 3.91 0 0027.817 9h0a3.906 3.906 0 00-3.707 2.672h0a6.694 6.694 0 00.995 6.134L26 19l-.595 4.761c-.122.978 1.096 1.525 1.745.783l4.098-4.683a1 1 0 011.505 0l4.098 4.683c.649.742 1.867.196 1.745-.783L38 19l.895-1.194a6.695 6.695 0 00.995-6.134h0A3.906 3.906 0 0036.183 9h0c-.772 0-1.526.228-2.168.656L32 11z"
      ></path>
      <path
        fill="none"
        stroke="#000"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M10 33H24V39H10z"
      ></path>
      <path
        fill="none"
        stroke="#000"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M40 33H54V39H40z"
      ></path>
      <path
        fill="none"
        stroke="#000"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M32 47L32 61"
      ></path>
      <path
        fill="none"
        stroke="#000"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M28 52H36V56H28z"
      ></path>
      <path
        fill="none"
        stroke="#000"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M47 33L47 39"
      ></path>
      <path
        fill="none"
        stroke="#000"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M17 33L17 39"
      ></path>
      <path
        fill="none"
        stroke="#000"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M50 47H54V57H50z"
      ></path>
      <path
        fill="none"
        stroke="#000"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M10 47H14V57H10z"
      ></path>
    </svg>
  );
};

export default DentistOfficeIcon;
