import React from "react";

const PhoneIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      x="0"
      y="0"
      data-name="Layer 1"
      viewBox="0 0 100 100"
      id="phone-icon"
    >
      <path d="M46 93a20.4 20.4 0 008.73 1.94h.12c7.28 0 13.23-2 17.69-5.83a23.21 23.21 0 006.2-8.86 2 2 0 00-1.4-2.69 73.92 73.92 0 01-22-9.17 2 2 0 00-2.27.11l-7.41 5.67a75.18 75.18 0 01-20.75-21.25l5.22-7.27a2 2 0 00.07-2.22 74.6 74.6 0 01-9.5-22.91A2 2 0 0018.17 19a24 24 0 00-7.74 3.87 20.13 20.13 0 00-2.72 2.37C3.15 30 1.78 37.56 4.13 44.92A80.76 80.76 0 0046 93zM10.59 28a16.49 16.49 0 012.23-1.93 18.86 18.86 0 014.5-2.54 78.69 78.69 0 008.79 20.87l-5.24 7.29a2 2 0 00-.07 2.23 79.11 79.11 0 0023.87 24.4 2 2 0 002.3-.09l7.45-5.7A78 78 0 0074 80.87a18.68 18.68 0 01-4.15 5.25c-3.7 3.21-8.76 4.84-15.05 4.84h-.11a16.37 16.37 0 01-7-1.55 76.74 76.74 0 01-39.75-45.7C6 37.74 7.06 31.74 10.59 28z"></path>
      <path d="M39.67 37.23a2 2 0 10-.75 3.93 27.9 27.9 0 0121.35 19.45 2 2 0 103.84-1.12 31.93 31.93 0 00-24.44-22.26zM38.53 21.13a2 2 0 10-.4 4A43.59 43.59 0 0176.9 63.71a2 2 0 002 1.79h.21a2 2 0 001.78-2.2 47.61 47.61 0 00-42.36-42.17z"></path>
      <path d="M79.09 24.21A63 63 0 0035.62 5.05 2 2 0 0033.56 7a2 2 0 001.94 2.05A59 59 0 0193 68.35a2 2 0 004 0 63 63 0 00-17.91-44.14z"></path>
    </svg>
  );
};

export default PhoneIcon;
