import React, { Component } from "react";
import Carousel from "react-elastic-carousel";
import {
  WaitingRoom,
  WaitingRoom2,
  FrontDesk,
  MainHall,
  XRay,
  PatientRoom,
  PatientRoom2,
  PatientRoom3,
  InfantPatientRoom2,
  SterilizationRoom,
} from "../../../../assets/office-tour/index";
import "./OfficeTour.css";

class OfficeTour extends Component {
  state = {
    items: [
      { id: 1, title: "Waiting Room", img: WaitingRoom },
      { id: 2, title: "Waiting Room", img: WaitingRoom2 },
      { id: 3, title: "Front Desk", img: FrontDesk },
      { id: 4, title: "Main Hallway", img: MainHall },
      { id: 5, title: "Panoramic Digital X-Ray", img: XRay },
      { id: 6, title: "Patient Room", img: PatientRoom },
      { id: 7, title: "Patient Room", img: PatientRoom2 },
      { id: 8, title: "Patient Room", img: PatientRoom3 },
      { id: 10, title: "Treatment Room", img: InfantPatientRoom2 },
      { id: 11, title: "Sterilization Room", img: SterilizationRoom },
    ],
  };
  render() {
    const { items } = this.state;
    return (
      <div className="office-tour subpage">
        <header className="office-tour-header subpage-header">
          <h1>Office Tour</h1>
        </header>
        <section className="office-tour-content subpage-content">
          <Carousel>
            {items.map((item) => (
              <div key={item.id}>
                {<img src={item.img} alt={item.title}></img>}
                {<p>{item.title}</p>}
              </div>
            ))}
          </Carousel>
        </section>
      </div>
    );
  }
}

export default OfficeTour;
