import React from "react";
import Soccer from "../../../../assets/sidebar/soccer.jpg";
import KidFootball from "../../../../assets/sidebar/kid-football.jpg";
import "./EmergencyCare.css";

const EmergencyCare = () => {
  return (
    <div className="emergency-care subpage">
      <header className="emergency-care-header subpage-header">
        <h1>Emergency Care</h1>
      </header>
      <section className="welcome">
        <article>
          <p>
            If this is an emergency, please all our office at{" "}
            <a href="tel:4157853445">415-785-3445.</a> If it is after business
            hours, there will be information on our voicemail how to reach Dr.
            Sophie directly.
          </p>{" "}
          <p>
            If your child experiences a dental emergency, whether it’s trauma or
            pain/infection we are here for you 24 hours a day. For established
            patients, most emergencies can be managed over the phone, but there
            are times when it may be necessary to meet at the office.
          </p>
        </article>
      </section>
      <section className="subpage-content emergency-care-content">
        <article className="knocked">
          <header>
            <h2>Your child's tooth has been knocked out:</h2>
          </header>
          <h3>Did your child hit their head?</h3>
          <p>
            ​Look for any signs of a concussion. This can include nausea,
            vomiting, loss of balance, or headache. Proceed to ER if necessary.
          </p>
          <br />
          <h3>Are there any broken or missing teeth?</h3>
          <p>
            ​If so pick up the tooth by the crown (NOT the root). Rinse it with
            cold water and place it in an appropriate container such as a{" "}
            <a
              href="https://www.amazon.com/Phoenix-Lazerus-Inc-A100-Save-A-Tooth-Preserving/dp/B003PERTBQ"
              target="_blank"
              rel="noreferrer"
            >
              Save-a-tooth
            </a>{" "}
            kit or a container filled with either: Hanks Balanced Solution,
            Milk, Saliva, Coconut Water, or Egg White. Contact us right away. If
            there is bleeding in your child's mouth, get a moist towel and apply
            pressure to stop the bleeding.
          </p>
          <br />
          <h3>Is it a permanent tooth?</h3>
          <p>
            ​If you are CERTAIN that the tooth is a permanent tooth and you are
            able to reposition the tooth right away, hold it by the crown, rinse
            the tooth with water, and reposition the tooth in the empty socket.
            The child should then bite on a towel to stabilize the tooth. If you
            are not comfortable doing this please place the tooth in one of the
            containers listed above and call our office.
          </p>
        </article>
        <img
          src={Soccer}
          alt="Kid playing soccer"
          className="soccer side-img"
        />
        <article>
          <header>
            <h2>
              Your child is expriencing a lot of pain / You notice an infection:
            </h2>
          </header>
          <p>
            Follow the steps below to assess the seriousness of the pain and
            check for infection.
          </p>
          <ul>
            <li>
              If you notice bleeding, get a moist towel and apply pressure to
              stop the bleeding.
            </li>
            <li>
              Look for any food that may be stuck, then brush and floss the area
              well. This may cause bleeding and be a little uncomfortable.
            </li>
            <li>
              Conduct an at home evaluation to assess the pain:{" "}
              <ol>
                <li>
                  Ask your child to localize the area of pain and look at this
                  carefully (you can use the flashlight function of your phone)
                </li>
                <li>
                  Check for swelling in the area of pain. Look for any holes in
                  teeth, discolored or broken teeth that could be causing the
                  pain.
                </li>
                <li>
                  Try to figure out what triggers to the pain. Cold? Heat?
                  Touch?
                </li>
                <li>
                  Ask your child to rate the severity of their pain, both when
                  resting, then with the trigger.
                </li>
              </ol>
            </li>
            <li>
              If needed, give your child something to manage the pain. While we
              recommend Ibuprofen and/or Tylenol for pain control, be sure to
              check with your pediatrician to make sure these are safe for your
              child.{" "}
            </li>
          </ul>
          <p>
            Some situations may require antibiotics, while others do not. We
            recommend, after assessing the situation, that you call our office
            and talk with Dr. Sophie.
          </p>
        </article>
        <img
          src={KidFootball}
          alt="Kid playing football"
          className="football side-img"
        />
      </section>
    </div>
  );
};

export default EmergencyCare;
