import React from "react";
import Infection from "../../../../assets/icons/covid/Infection";
import PPE from "../../../../assets/icons/covid/PPE";
import Distancing from "../../../../assets/icons/covid/Distancing";
import "./CovidHome.css";

const CovidHome = () => {
  return (
    <div className="covid-home">
      <article>
        <h2>
          Our commitment to your health and safety remains our #1 priority
        </h2>
        <ul>
          <li>
            <Infection />
            Enhanced
            <br /> Sterlization Protocol
          </li>
          <li>
            <PPE />
            Advanced <br />
            PPE & Screening
          </li>
          <li>
            <Distancing />
            Social <br /> Distancing
          </li>
        </ul>
        <p>
          At Sunnyside, we are keeping up to date with the enhanced
          sterilization protocol and social distancing recommendations of the
          American/California Dental Associations, California Department of
          Public Health, Center for Disease Control, and Occupational Safety and
          Health Administration.
        </p>
      </article>
    </div>
  );
};

export default CovidHome;
