import React from "react";

const HappyDentist = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      x="0"
      y="0"
      viewBox="0 0 12.7 12.7"
      id="happy-dentist"
    >
      <path
        d="M-4221.863 647.07a2.563 2.563 0 00-2.462 2.535v1.34a1.633 1.633 0 01-.198.779 2.634 2.634 0 00-.195 2.055l-.391.159a1.243 1.243 0 00-.774 1.151v2.3a.39.39 0 00.39.39h7.462a.39.39 0 00.39-.39v-2.3a1.243 1.243 0 00-.775-1.151l-.39-.16a2.634 2.634 0 00-.195-2.054 1.633 1.633 0 01-.198-.78v-1.34a2.563 2.563 0 00-2.664-2.534zm.032.183a2.353 2.353 0 012.421 2.352v1.34a1.844 1.844 0 00.224.88 2.423 2.423 0 01.184 1.874l-1.386-.564a.464.464 0 01-.29-.43v-.408a1.918 1.918 0 00.834-1.582v-.831a.221.221 0 00-.194-.22 3.85 3.85 0 01-2.274-1.123.221.221 0 00-.366.08 1.671 1.671 0 01-.873.953.221.221 0 00-.129.2v.941a1.918 1.918 0 00.833 1.582v.408a.464.464 0 01-.289.43l-1.386.564a2.423 2.423 0 01.184-1.875 1.844 1.844 0 00.223-.88v-1.34a2.353 2.353 0 012.284-2.351zm-.639 1.424a1.882 1.882 0 010 .003 4.06 4.06 0 002.407 1.194.01.01 0 01.005.002.01.01 0 010 .003.01.01 0 010 .003.01.01 0 010 .003v.832a1.707 1.707 0 01-3.414 0v-.94a.01.01 0 010-.006.01.01 0 01.002-.003.01.01 0 01.006-.003 1.882 1.882 0 00.987-1.085 4.06 4.06 0 01-.002-.003zm-.166 3.746a1.918 1.918 0 001.748 0v.282a.674.674 0 00.382.609l-1.256 1.244-1.257-1.244a.674.674 0 00.383-.609zm-.594.978l1.363 1.35v2.818h-3.626a.18.18 0 01-.18-.18v-2.3a1.033 1.033 0 01.644-.956l1.042-.424v.356a.772.772 0 00-.647.594l-.363 1.6a.342.342 0 00.08.306.754.754 0 00.348.218.228.228 0 10.031-.212.543.543 0 01-.224-.148.132.132 0 01-.03-.117l.363-1.6a.561.561 0 011.094 0l.363 1.6a.132.132 0 01-.031.117.543.543 0 01-.224.148.228.228 0 10.032.212.754.754 0 00.348-.218.342.342 0 00.08-.306l-.363-1.6a.772.772 0 00-.647-.594v-.442zm2.936 0l.705.287v.97a.829.829 0 10.211 0v-.884l.883.359a1.033 1.033 0 01.644.956v2.3a.18.18 0 01-.18.18h-3.626v-2.819zm.811 1.442a.618.618 0 01.618.636.618.618 0 11-1.236 0 .618.618 0 01.618-.636z"
        transform="translate(0 -284.3) matrix(1.0868 0 0 1.0868 4594.551 -418.402)"
      ></path>
      <path
        d="M-4220.651 650.245a.229.229 0 10-.457 0 .229.229 0 10.457 0zM-4222.416 650.245a.229.229 0 10-.457 0 .229.229 0 10.457 0zM-4222.255 651.093a.105.105 0 01.203-.055.3.3 0 00.58 0 .105.105 0 01.203.055.51.51 0 01-.986 0z"
        transform="translate(0 -284.3) matrix(1.0868 0 0 1.0868 4594.551 -418.402)"
      ></path>
    </svg>
  );
};

export default HappyDentist;
