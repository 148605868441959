import React from "react";
import { HashLink } from "react-router-hash-link";
import BlueWave from "../../../../assets/background/WavyUp";
import "./SunnysideDifference.css";

const SunnysideDifference = () => {
  const scrollWidthOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -120;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };
  return (
    <>
      <section className="sunnyside-difference-home">
        <h2 className="sunnyside-difference-home-title">
          The Sunnyside Dental Home Experience
        </h2>
        <article className="sunnyside-difference-home-article">
          <p className="sunnyside-difference-p-1">
            Whether your child is new to the dentist, or has been many times,
            our goal is to provide your family with a fun, compassionate and
            educational dental home experience. We believe that a strong
            patient-dentist relationship founded on trust fosters confidence and
            cooperation. At each visit we work on developing the daily dietary
            routines and hygiene habits/skills that promote improved lifelong
            oral health outcomes. As your pediatric dental home, we are here to
            help your child grow up with strong happy and healthy smiles.
          </p>
        </article>
        <HashLink
          to="/contact#contact-form"
          scroll={scrollWidthOffset}
          className="appointment-btn"
        >
          <button>Request an Appointment</button>
        </HashLink>
      </section>
      <BlueWave />
    </>
  );
};

export default SunnysideDifference;
