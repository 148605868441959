import React from "react";

const TV = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      x="0"
      y="0"
      fill="#1A1A1A"
      viewBox="0 0 100 100"
      className="tv-icon"
    >
      <path fill="none" d="M4.999 5H94.99799999999999V95H4.999z"></path>
      <path d="M93.813 19.792H6.186c-.657 0-1.188.532-1.188 1.188v52.421c0 .655.531 1.188 1.188 1.188h16.789v2.486c0 1.729 1.187 3.133 2.644 3.133h48.896c1.456 0 2.643-1.404 2.643-3.133V74.59h16.656c.656 0 1.188-.533 1.188-1.188V20.98a1.19 1.19 0 00-1.189-1.188zM74.779 77.076c0 .435-.197.708-.285.756H25.637c-.089-.048-.284-.321-.284-.757V74.59H74.78v2.486zm17.845-4.864H7.374V22.168h85.25v50.044z"></path>
      <path d="M9.095 70.967h81.808a.475.475 0 00.475-.476V23.889a.476.476 0 00-.475-.476H9.095a.476.476 0 00-.476.476v46.602c0 .263.213.476.476.476zm.475-46.602h80.856v45.651H9.57V24.365z"></path>
      <path d="M49.999 28.06c-10.549 0-19.131 8.582-19.131 19.131 0 10.547 8.582 19.129 19.131 19.129 10.548 0 19.13-8.582 19.13-19.129 0-10.549-8.582-19.131-19.13-19.131zm0 36.834c-9.763 0-17.704-7.941-17.704-17.702 0-9.763 7.941-17.705 17.704-17.705 9.762 0 17.704 7.942 17.704 17.705 0 9.76-7.942 17.702-17.704 17.702z"></path>
      <path d="M42.415 34.189a.713.713 0 00-.355.618v24.766a.712.712 0 001.068.617l21.448-12.382a.714.714 0 000-1.236L43.129 34.189a.717.717 0 00-.714 0zm1.07 1.853l19.309 11.149-19.309 11.147V36.042z"></path>
    </svg>
  );
};

export default TV;
