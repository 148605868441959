import React from "react";
import { Link } from "react-router-dom";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import PhoneIcon from "../../assets/icons/footer/PhoneIcon";
import TextIcon from "../../assets/icons/TextIcon";
import GoogleIcon from "../../assets/icons/social/GoogleIcon";
import FacebookIcon from "../../assets/icons/social/FacebookIcon";
import InstagramIcon from "../../assets/icons/social/InstagramIcon";
import "./Header.css";

const Header = () => {
  return (
    <header className="main-header">
      <div className="header-contact">
        <div className="header-title">
          <span>
            <Link to="/">Sunnyside Pediatric Dentistry</Link>
          </span>
        </div>
        <div className="header-phone">
          <a href="tel:4157853445">
            <PhoneIcon />
          </a>
          <span>415-785-3445</span>
          <a href="sms:4157853445">
            <TextIcon />
          </a>
        </div>
        <div className="header-social">
          <a
            href="https://facebook.com/sunnysidedentistry"
            target="_blank"
            rel="noreferrer noopener"
          >
            <FacebookIcon />
          </a>
          <a
            href="https://www.instagram.com/sunnysidedentistry/"
            target="_blank"
            rel="noreferrer noopener"
          >
            <InstagramIcon />
          </a>
          <a
            href="https://search.google.com/local/reviews?placeid=ChIJ981Wb1iXhYARgKfk3ML2DSU&q=*&hl=en&gl=IT"
            target="_blank"
            rel="noreferrer noopener"
          >
            <GoogleIcon />
          </a>
        </div>
      </div>
      <Navbar collapseOnSelect expand="lg" className="navbar pl-2 pt-1 pb-0">
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          className=""
          id="mobile-button"
        />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto text-center container-fluid align-items-center navbar-text-size custom-background">
            <Nav.Link as={Link} to="/" className="navbar-link" eventKey="1">
              HOME
            </Nav.Link>
            <NavDropdown title="ABOUT US" className="navbar-link">
              <NavDropdown.Item as={Link} to="/why-sunnyside" eventKey="2">
                WHY SUNNYSIDE
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/our-philosophy" eventKey="3">
                OUR PHILOSOPHY
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/dr-sophie" eventKey="4">
                DR. SOPHIE
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/meet-the-team" eventKey="5">
                MEET THE TEAM
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/office-tour" eventKey="6">
                OFFICE TOUR
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="SERVICES" className="navbar-link">
              <NavDropdown.Item as={Link} to="dental-services" eventKey="7">
                DENTAL SERVICES
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="emergency-care" eventKey="8">
                EMERGENCY CARE
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="patient-education" eventKey="10">
                PATIENT EDUCATION
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="PATIENTS" className="navbar-link">
              <NavDropdown.Item as={Link} to="new-patients" eventKey="11">
                NEW PATIENTS
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="faq" eventKey="15">
                FAQ
              </NavDropdown.Item>
            </NavDropdown>

            <Nav.Link
              as={Link}
              to="/contact"
              className="navbar-link"
              eventKey="16"
            >
              CONTACT
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/covid-19"
              className="navbar-link"
              eventKey="17"
            >
              COVID-19
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/emergency-care"
              className="navbar-link"
              eventKey="18"
            >
              EMERGENCIES
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </header>
  );
};

export default Header;
