import React from "react";

const Spreadsheet = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      x="0"
      y="0"
      fill="#1A1A1A"
      enableBackground="new 0 0 100 100"
      viewBox="0 0 100 100"
    >
      <path d="M89 10H11a1 1 0 00-1 1v78a1 1 0 001 1h78a1 1 0 001-1V11a1 1 0 00-1-1zM28 75V64h14v11H28zm14 2v11H28V77h14zm0-52v11H28V25h14zm-14-2V12h14v11H28zm-2 26H12V38h14v11zm2-11h14v11H28V38zm-2 13v11H12V51h14zm2 0h14v11H28V51zm16 0h44v11H44V51zm0-2V38h44v11H44zm0-13V25h44v11H44zm-18 0H12V25h14v11zM12 64h14v11H12V64zm32 0h44v11H44V64zm44-41H44V12h44v11zM26 12v11H12V12h14zM12 77h14v11H12V77zm32 11V77h44v11H44z"></path>
    </svg>
  );
};

export default Spreadsheet;
