import React from "react";

const Droplet = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      x="0"
      y="0"
      fill="#1A1A1A"
      enableBackground="new 0 0 512 512"
      viewBox="0 0 512 512"
      className="toothpaste-icon"
    >
      <path d="M454.811 57.181c-16.021-15.949-37.932-26.42-48.619-15.625l-54.321 54.588c-7.622-3.827-16.767-4.227-24.776-.608a144.086 144.086 0 00-34.394 21.84L75.75 302.995c-.448.383-.72.903-1.059 1.373-.345.242-.735.385-1.043.693l-34.52 34.52a7.365 7.365 0 00-2.155 5.262 7.386 7.386 0 002.227 5.226l62.964 61.274.004.007c.002.002.006.003.008.006l20.988 20.423c.008.008.01.02.018.028.009.009.024.012.033.021l42.075 40.946a7.359 7.359 0 005.128 2.083 7.323 7.323 0 005.2-2.155l34.52-34.52c.109-.109.144-.256.245-.369.144-.143.341-.212.474-.367l184.56-217.859a143.984 143.984 0 0021.66-34.484c3.594-8.026 3.162-17.177-.711-24.795l54.304-54.571c10.741-10.803.162-32.607-15.859-48.556zm-32.759 32.921c1.478 1.474 3.014 2.821 4.547 4.167l-37.104 37.219-9.584-9.535 37.11-37.287c1.594 1.843 3.254 3.67 5.031 5.436zm-22.131 51.759l38.636-38.756c2.798 1.696 5.583 3.221 8.28 4.33.387.158.687.193 1.063.336l-40.917 41.117-7.062-7.027zm60.296-46.523c-2.353 1.069-14.207-2.137-27.785-15.661-13.837-13.756-16.911-25.626-15.913-27.65l.011-.011c.272-.152.713-.231 1.282-.231 3.969 0 14.566 3.826 26.617 15.823 13.597 13.541 16.794 25.242 15.788 27.73zm-55.916-31.033c.154.401.14.681.311 1.093.972 2.353 2.25 4.766 3.677 7.195l-38.804 38.988-6.146-6.114 40.962-41.162zM80.552 318.972l10.352 10.304-25.633 25.633-10.472-10.191 25.753-25.746zm58.312 107.555l24.826-24.82 10.348 10.298-24.708 24.708-10.466-10.186zm-10.55-10.267l-10.469-10.188 25.055-25.055 10.356 10.307-24.942 24.936zm-21.018-20.454l-10.463-10.182 25.286-25.286 10.347 10.298-25.17 25.17zm-21.012-20.449l-10.463-10.182 25.517-25.517 10.347 10.298-25.401 25.401zm84.063 81.809l-10.467-10.187 24.591-24.591 10.349 10.298-24.473 24.48zm233.295-278.052a128.83 128.83 0 01-19.469 30.964L204.786 421.856 91.376 308.994l210.881-180.438a129.387 129.387 0 0130.874-19.604c5.298-2.398 11.584-1.257 15.697 2.847l15.471 15.393c.006.006.006.011.011.017.006.006.012.006.018.011l36.388 36.206c4.112 4.085 5.297 10.389 2.926 15.688z"></path>
      <path d="M287.583 245.27c-2.892-2.326-7.346-5.828-11.315-8.549-2.649-3.888-6.008-8.127-8.684-11.36-3.134-3.808-6.654-6.681-10.462-8.531-13.722-6.699-25.593.763-28.692 4.58-9.016 6.484-16.604 26.339-18.005 30.191-.808 2.155-3.745 5.954-4.93 7.337l-11.962 10.309c-6.708 5.981-10.372 13.614-12.258 18.948a109.785 109.785 0 01-1.823 4.724c-2.703 6.502-.278 11.243 2.937 13.524 3.933 2.784 9.285 2.263 12.761-1.185l7.992-8.028c3.799-3.808 9.555-8.046 15.275-6.466 1.967.539 3.421 1.976 3.978 3.951 1.607 5.729-2.586 11.477-6.403 15.302L208 318.063c-3.457 3.484-3.906 8.872-1.087 12.788 1.581 2.209 4.355 4.041 8.064 4.041 1.625 0 3.439-.359 5.397-1.185a101.785 101.785 0 014.733-1.85c4.373-1.563 12.644-5.262 18.993-12.464l9.546-11.261c2.003-1.85 5.792-4.795 7.876-5.604 3.906-1.437 23.744-9.106 29.078-16.937 4.867-4.346 12.285-16.2 5.532-29.976-1.886-3.798-4.777-7.273-8.549-10.345zm-7.885 30.569c-2.532 3.574-14.063 9.483-23.366 12.931-5.523 2.119-11.504 7.471-13.255 9.16l-7.346 8.603c2.488-6.717 1.805-12.339.826-15.805-1.958-6.951-7.292-12.249-14.243-14.153a25.003 25.003 0 00-6.573-.898c-2.766 0-5.837.467-9.133 1.706l9.124-7.912c1.051-1.149 6.385-7.157 8.486-12.761 3.386-9.241 9.232-20.798 13.955-24.39.889-.961 5.684-5.586 12.518-2.254 1.886.916 3.754 2.479 5.55 4.661 3.457 4.194 6.34 7.983 8.226 10.821.171.305.359.602.575.889.332.521.62 1.006.871 1.446a7.341 7.341 0 005.891 3.736 217.046 217.046 0 016.511 5.092c2.137 1.724 3.7 3.565 4.616 5.424 3.341 6.798-1.239 11.638-3.233 13.704z"></path>
      <path d="M247.316 192.853a7.355 7.355 0 00-6.942 7.75c.225 4.05 3.835 7.193 7.75 6.942 16.362-.871 31.071 4.562 41.865 15.302 9.142 9.079 14.476 20.987 15.428 34.421a7.375 7.375 0 007.346 6.834c.162 0 .341 0 .521-.018a7.36 7.36 0 006.825-7.858c-1.221-17.035-8.046-32.185-19.738-43.814-13.812-13.731-32.634-20.654-53.055-19.559zM224.39 342.184c-16.335.79-31.08-4.544-41.883-15.284-9.124-9.088-14.458-21.014-15.419-34.448-.287-4.059-3.745-6.771-7.858-6.825-4.059.305-7.103 3.826-6.816 7.867 1.212 17.044 8.028 32.203 19.711 43.823 12.842 12.77 30.057 19.667 48.843 19.667 1.392 0 2.802-.036 4.212-.108 4.059-.216 7.175-3.682 6.951-7.741s-3.709-7.005-7.741-6.951z"></path>
    </svg>
  );
};

export default Droplet;
