import React from "react";
import GirlMouthMirror from "../../../../assets/sidebar/girl-mouth-mirror.jpg";
import GlassesCool from "../../../../assets/sidebar/glasses-cool.jpg";
import "./OurPhilosophy.css";

const OurPhilosophy = () => {
  return (
    <div className="our-philosophy subpage">
      <header className="our-philosophy-header subpage-header">
        <h1>The Sunnyside Practice Philosophy</h1>
      </header>
      <section className="welcome">
        <article>
          <p>
            We are a pediatric dental home focused on practical prevention and
            minimally invasive care. We strive to be the dental office you wish
            you had visited when you were a kid! Our dental practice philosophy
            is built on five pillars we believe lead to long term successful
            oral health and wellness outcomes.
          </p>
        </article>
      </section>
      <section className="subpage-content our-philosophy-content">
        <article className="positive-experiences">
          <header>
            <h2>Positive Experiences</h2>
          </header>
          <h3>
            A positive office experience is the foundation for good oral care
          </h3>
          <p>
            Our goal is to provide your children with a fun, compassionate
            dental home. We believe that strong patient-dentist relationships
            based on trust and cooperation, builds confidence and self-efficacy.
            We work hard to earn your child’s trust from day one, and understand
            that the development of a long term positive relationship with oral
            healthcare does not happen in a single visit. Over time, the
            relationships you build at Sunnyside will set your child up for a
            lifetime of positive dental care experiences.
          </p>
        </article>
        <img
          src={GlassesCool}
          alt="boy in dentist chair"
          className="glasses-cool side-img"
        />
        <article>
          <header>
            <h2>Integrated Dental Care</h2>
          </header>
          <h3>Oral health and overall health are connected</h3>
          <p>
            Studies show that your oral health may be a contributor to other
            health condition and some health conditions may be a contributor to
            your oral health. It is important in our practice at Sunnyside that
            we treat each patient as a whole and consider all aspects of
            wellness when developing treatment plans. This includes everything
            from educating patients about common sense snacking to taking into
            account behavioral and medical histories.
          </p>
        </article>
        <article>
          <header>
            <h2>Prevention First</h2>
          </header>
          <h3>The best treatment is prevention</h3>
          <p>
            When healthy habits are combined with the early diagnosis of decay
            and gingival infections, there is the opportunity for the use of
            minimally invasive treatment options (ie: topically applied
            treatments, and “no-numbing” atraumatic restorative techniques).
            While we prefer this approach, sometimes more advanced treatments
            are needed. In these cases, we develop personalized behavioral
            support plans which may include nitrous oxide or sedation/general
            anesthesia. Although we try to limit our use of these methods.
          </p>
        </article>
        <img
          src={GirlMouthMirror}
          alt="Girl at dentist"
          className="girl-mouth-mirror side-img"
        />
        <article>
          <header>
            <h2>Family Centered</h2>
          </header>
          <h3>Good habits form at home</h3>
          <p>
            We are committed to supporting the development of positive oral
            health habits at home, which is what will really make the difference
            long term for your children’s oral health. Using ongoing education
            and take home tools, we support the development of family centered
            preventive care, fostering healthy dental habits at home including
            common sense snacking and oral hygiene practices.
          </p>
        </article>
        <article>
          <header>
            <h2>Customized Plans</h2>
          </header>
          <h3>One size does not fit all</h3>
          <p>
            No two children are the same. It is central to our approach that we
            take the time to develop a personalized care for each patient based
            on your children’s individual dental treatment and behavioral
            support needs.
          </p>
        </article>
      </section>
    </div>
  );
};

export default OurPhilosophy;
