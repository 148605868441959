import React from "react";
import Mask from "../../../assets/sidebar/mask.jpg";
import "./Covid.css";

const Covid = () => {
  return (
    <div className="covid subpage">
      <header className="covid-header subpage-header">
        <h1>Covid-19</h1>
      </header>
      <section className="welcome">
        <article>
          <p>
            Before your visit please review the files below so you are prepared
            for the updates you will see.
          </p>
        </article>
      </section>
      <section className="subpage-content covid-content">
        <article className="before-visit">
          <div className="covid-btns">
            <a
              href="/covid-protocols.jpg"
              target="_blank"
              rel="noreferrer noopener"
            >
              <button>Protocols</button>
            </a>
            <a
              href="/virtual-check-in.jpg"
              target="_blank"
              rel="noreferrer noopener"
            >
              <button>Virtual Check-In</button>
            </a>
          </div>
        </article>
        <article className="commitment">
          <header>
            <h2>
              Our commitment to your health and safety remains our #1 priority
            </h2>
          </header>
          <p>
            At Sunnyside, we are keeping up to date with the enhanced
            sterlization protocols and social distancing recommendations of the
            American/California Dental Associations, California Department of
            Public Health, Center for Disease Control, and Occupational Safety
            and Health Administration. We have invested in additional
            technologies, PPE and other safety products while implementing
            social distancing protocols to improve our existing advanced
            sterilzation protocols.
          </p>
        </article>
        <article className="enhanced">
          <header>
            <h3>Enhanced Infection Control</h3>
          </header>
          <ul>
            <li>Air Purifiers with increased air exchanges</li>
            <li>Continued use of hydrogen peroxide based cleaners</li>
            <li>Appointment Pre-Screening​</li>
            <li>Improved High Volume Suction</li>
            <li>
              Emphasis on minimally invasive low-aerosol generating procedures
            </li>
            <li>
              BYOE: Bring your own entertainments - Free Wifi + Movies on the
              ceiling available
            </li>
          </ul>
        </article>
        <article className="advanced">
          <header>
            <h3>Advanced PPE & Screening</h3>
          </header>
          <ul>
            <li>
              Additional PPE Gear added based on the procedures being completed
            </li>
            <li>
              Universal Masking of staff, guardians and patients whenever
              possible​
            </li>
            <li>Appointment Pre-Screening survey</li>
            <li>Temperature Checks & possible Oxygen Saturation Screening</li>
            <li>Staff Covid Testing​</li>
          </ul>
        </article>
        <img src={Mask} alt="Mask" className="mask side-img" />
        <article className="social-distancing">
          <header>
            <h3>Social Distancing</h3>
          </header>
          <ul>
            <li>Online Paperwork</li>
            <li>Online Payments</li>
            <li>Socially Distanced Scheduling</li>
            <li>Virtual Visits Offered whenever appropriate and possible</li>
            <li>Closed Private Treatment Tooms</li>
          </ul>
        </article>
      </section>
    </div>
  );
};

export default Covid;
