import React from "react";
import DrSophiePose from "../../../../assets/dr-sophie/dr-sophie-front-desk.jpg";
import AAPD from "../../../../assets/logos/aapd.png";
import CDA from "../../../../assets/logos/cda.png";
import ADA from "../../../../assets/logos/ada.png";
import "./DrSophie.css";

const DrSophie = () => {
  return (
    <div className="dr-sophie subpage">
      <header className="dr-sophie-header subpage-header">
        <h1>Dr. Sophie</h1>
      </header>
      <section className="subpage-content dr-sophie-content">
        <article className="dr-sophie-bio">
          <img src={DrSophiePose} alt="Dr. Sophie" id="dr-sophie-pose" />
          <p>
            Dr. Sophie is a Board Certified pediatric dental specialist. She
            received her Doctorate of Dental Surgery (DDS) from University of
            the Pacific Arthur A. Dugoni School of Dentistry in San Francisco,
            CA.
          </p>
          <br />
          <p>
            After working for several years as a family dentist, Dr. Sophie
            wanted to focus specifically on providing care to kids. She moved to
            New York where she completed a residency training on the care of
            patients with special health care needs at St. Charles Hospital in
            Eastern Long Island, NY. She then spent two additional years in
            pediatric specialty training at the NYU-Lutheran Medical Center, a
            level one trauma center in Brooklyn, NY. After completing her
            training, Dr. Sophie accepted a position at the Santa Rosa Community
            Dental Center in Santa Rosa, CA before opening her own practice in
            San Rafael, CA.
          </p>
          <br />
          <p>
            Dr. Sophie is fluent in French and Spanish and enjoys creating
            customized behavior guidance plans that encourage each child to have
            a positive dental experience. If your child is particularly nervous
            about dental visits, please be sure to let us know so she can
            customize a plan for them.
          </p>
        </article>
        <article className="dr-sophie-education">
          <header>
            <h2>Education</h2>
          </header>
          <ul className="education-list">
            <li>
              NYU-Lutheran Medical Center ​<br />
              ​Brooklyn, NY - ​Pediatric Dental Specialty - Dr. Perez Award for
              Excellence + Caring in Pediatric Dentistry
            </li>
            <li>
              St. Charles Hospital <br />
              Port Jefferson, NY - General Residency - Emphasis of the care of
              patients with Special Needs
            </li>
            <li>
              University of the Pacific Arthur A. Dugoni School of Dentistry
              <br />
              San Francisco, CA - Doctorate of Dental Surgery
            </li>
            <li>
              UC San Diego - La Jolla, CA - BS Cognitive Sciences with Emphasis
              in Neuroscience
            </li>
          </ul>
        </article>
        <aside className="dr-sophie-memberships">
          <header>
            <h2>Professional Memberships</h2>
          </header>
          <ul className="memberships-list">
            <li>
              <img
                src={AAPD}
                alt="American Academy of Pediatric Dentistry logo"
                id="aapd"
              />
              <a href="http://www.aapd.org" target="_blank" rel="noreferrer">
                American Academy of Pediatric Dentistry
              </a>
            </li>
            <li>
              <img
                src={CDA}
                alt="California Dental Association logo"
                id="cda"
              />
              <a href="http://www.cda.org" target="_blank" rel="noreferrer">
                California Dental Association
              </a>
            </li>
            <li>
              <img src={ADA} alt="American Dental Association logo" id="ada" />
              <a href="http://www.ada.org" target="_blank" rel="noreferrer">
                American Dental Association
              </a>
            </li>
          </ul>
        </aside>
        <article className="dr-sophie-more">
          <header>
            <h2>A little more ...</h2>
          </header>
          <p>
            Dr. Sophie grew up in a French/English bilingual home in Berkeley,
            CA and is a California native through and through. After going to
            Ecole Bilingue for K-8, she attend Head Royce for high school and UC
            San Diego for college. In addition to being an awesome Pediatric
            Dentist, Dr. Sophie is a retired competitive figure skater and
            coach, speaks both French and Spanish, and has traveled to over 16
            different countries! She is married and has a two sons. She spends
            her free time going on hikes with her family, including her favorite
            dog, a black lab mix named Buddy - who also likes to brush his
            teeth.
          </p>
        </article>
      </section>
    </div>
  );
};

export default DrSophie;
